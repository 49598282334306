import type { StatusType } from '../../../../../../domain'
import type { SubscriptionsTypes } from '../../../../../../application'

import type { ConnectionProxyParams, StatusSupportedType } from '../../../../../Connectware'
import type { ConnectionProxy } from '../../../../proxies'
import { createStatusEventsHandler, SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcInstanceToOneSubscriptionHandler } from '..'

type BaseConnectionHandler<T extends keyof SubscriptionsTypes> = VrpcInstanceToOneSubscriptionHandler<ConnectionProxy, SubscriptionsTypes[T]>
type BaseConnectionHandlerArgs<T extends keyof SubscriptionsTypes> = VrpcHandlerMappingPropertiesArgs<BaseConnectionHandler<T>>

export abstract class VrpcBaseConnectionProxyInstanceHandler<T extends keyof SubscriptionsTypes> implements BaseConnectionHandler<T> {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_ONE

    abstract readonly optionalFilters: BaseConnectionHandler<T>['optionalFilters']

    abstract readonly requiredFilters: BaseConnectionHandler<T>['requiredFilters']

    abstract readonly classNameFilter: BaseConnectionHandler<T>['classNameFilter']

    abstract readonly ignoreInstances: BaseConnectionHandler<T>['ignoreInstances']

    abstract readonly ignoreInstanceByFilter: BaseConnectionHandler<T>['ignoreInstanceByFilter']

    readonly sourceInstanceName = null

    readonly agent = null

    readonly onChange = createStatusEventsHandler(this.getStatusSupportedType())

    protected abstract mapConnection (connection: ConnectionProxyParams, status: StatusType): SubscriptionsTypes[T]

    protected abstract getStatusSupportedType (): StatusSupportedType

    mapToDomain ({ instance, rstAdapter }: BaseConnectionHandlerArgs<T>['DomainMapperArgs']): Promise<BaseConnectionHandlerArgs<T>['Domain']> {
        return Promise.all([instance.getParams(), rstAdapter.fetchStatusType(this.getStatusSupportedType(), instance._targetId)]).then(([params, statusType]) =>
            this.mapConnection(params, statusType)
        )
    }
}
