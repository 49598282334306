import React, { type FC } from 'react'
import type { SystemCssProperties } from '@mui/system'
import type { SxProps, Theme } from '@mui/material'
import { Lens } from '@mui/icons-material'

import type { ReadonlyRecord } from '../../../../utils'
import { StatusType } from '../../../../domain'

import type { BaseCommonProps } from '../Base'

type Props = Readonly<{ status: StatusType }> & Pick<BaseCommonProps, 'sx'>

const red: SxProps<Theme> = { color: 'red.500' }
const orange: SxProps<Theme> = { color: 'orange.500' }
const green: SxProps<Theme> = { color: 'green.500' }
const blue: SxProps<Theme> = { color: 'primary.main' }
const grey: SxProps<Theme> = { color: 'grey.500' }

const colors: ReadonlyRecord<StatusType, SystemCssProperties<Theme>> = {
    [StatusType.DISABLED]: red,
    [StatusType.DISCONNECTED]: red,
    [StatusType.EXITED]: red,
    [StatusType.INVALID]: red,
    [StatusType.OFFLINE]: red,
    [StatusType.PAUSED]: red,
    [StatusType.UNAVAILABLE]: red,
    [StatusType.UNREACHABLE]: red,

    [StatusType.CONNECTING]: orange,
    [StatusType.DEVIATED]: orange,
    [StatusType.DISABLING]: orange,
    [StatusType.DISCONNECTING]: orange,
    [StatusType.ENABLING]: orange,
    [StatusType.RECONNECTING]: orange,
    [StatusType.REENABLING]: orange,
    [StatusType.RESTARTING]: orange,
    [StatusType.ROLLING_BACK]: orange,

    [StatusType.ACTIVE]: green,
    [StatusType.AVAILABLE]: green,
    [StatusType.CONNECTED]: green,
    [StatusType.ENABLED]: green,
    [StatusType.NO_DEVIATION]: green,
    [StatusType.ONLINE]: green,
    [StatusType.REACHABLE]: green,
    [StatusType.RUNNING]: green,
    [StatusType.STARTING]: green,
    [StatusType.STOPPING]: green,
    [StatusType.VALID]: green,

    [StatusType.UPDATING]: blue,

    [StatusType.CREATED]: grey,
    [StatusType.DEAD]: grey,
    [StatusType.INACTIVE]: grey,
    [StatusType.NOT_ENABLED]: grey,
    [StatusType.TERMINATED]: grey,
    [StatusType.UNEXPECTED]: grey,
    [StatusType.UNKNOWN]: grey,
    [StatusType.WAITING]: grey,
}

export const StatusIcon: FC<Props> = ({ status, sx }) => {
    return <Lens fontSize="inherit" sx={{ ...colors[status], ...sx }} />
}
