import { areCybusNodesEquals, Capability, type CybusNode } from '../../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../../application'

import { CybusNodeMapper, type NodeProxyParams } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

export class NodesStrategy extends BaseSubscriptionStrategy<'nodes'> {
    readonly requiredPermissions = [Capability.NODES_READ]

    protected override readonly supportedFilters: (keyof SubscriptionFilterArgs)[] = ['server']

    protected override readonly areEquals = areCybusNodesEquals

    override async retrieveAll (filter: SubscriptionFilterArgs): Promise<CybusNode[]> {
        return this.retrieve({
            capability: Capability.NODES_READ,
            method: 'GET',
            path: '/api/nodes',
            mapper: (data: NodeProxyParams[]) => {
                const mapper = new CybusNodeMapper(filter, (id) => this.rstAdapter.fetchStatusType('nodes', id))

                data.forEach((param) => mapper.push(param))

                return mapper.values
            },
        })
    }
}
