import { Droppable, Throttler } from '../../../../../../utils'
import type { CybusEndpoint } from '../../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../../application'

import { CybusEndpointMapper, type EndpointProxyParams } from '../../../../../Connectware'
import { ENDPOINT_CLASSNAME_FILTER, PROTOCOL_MAPPER_RESOURCE_STATE_LISTENER } from '../../../../constants'
import type { EndpointProxy, ResourceStateListenerProxy } from '../../../../proxies'
import { createProxyEventsHandler, SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcInstanceToListSubscriptionHandler } from '..'

type EndpointsHandler = VrpcInstanceToListSubscriptionHandler<ResourceStateListenerProxy, CybusEndpoint>
type EndpointsHandlerArgs = VrpcHandlerMappingPropertiesArgs<EndpointsHandler>

/** Retrieve the content on the pattern */
const ENDPOINT_PATTERN = ENDPOINT_CLASSNAME_FILTER.toString().replace(/\//g, '')

const getEndpointsParams = (instance: ResourceStateListenerProxy, { service, connection }: SubscriptionFilterArgs): Promise<EndpointProxyParams[]> =>
    instance.getParams<EndpointProxy>(ENDPOINT_PATTERN, {
        serviceIds: service ? [service] : undefined,
        connectionIds: connection ? [connection] : undefined,
    })

interface ChainStepContext<V> {
    setPromise(valuePromise: Promise<V>): void
    getValue(): V
}

/**
 * Helper class to allow for chained promises to work along-side
 */
class ChainContext<V> implements ChainStepContext<V> {
    private stopped: boolean = false

    private readonly promises: Promise<V>[] = []

    constructor (private value: V) {}

    setPromise (promise: Promise<V>): void {
        void promise.then((value) => {
            this.value = value
        })
        this.promises.push(promise)
    }

    getValue (): V {
        return this.value
    }

    isStopped (): boolean {
        return this.stopped
    }

    async readiness (): Promise<void> {
        await Promise.all(this.promises)
    }

    stop (): void {
        this.stopped = true
    }
}

class StateListener {
    /**
     * Aux variable to represent who is making resource calls
     */
    private usage: number = 0

    /**
     * Collection of functions that will unhook state listener
     */
    private readonly offs: Promise<VoidFunction>[] = []

    constructor (private readonly args: EndpointsHandlerArgs['OnChangeArgs']) {}

    private killUsage (): void {
        /**
         * Break comparison forever
         */
        this.usage = NaN
    }

    private clearCurrentListeners (): Promise<VoidFunction[]> {
        const previousUnhooks = this.offs.splice(0)

        /** Quietly kill all previous listeners */
        for (const offPromise of previousUnhooks) {
            void offPromise.then((off) => off())
        }

        return Promise.all(this.offs)
    }

    private async fetchIds (): Promise<EndpointProxyParams['id'][]> {
        const parameters = await getEndpointsParams(this.args.instance, this.args.filter)
        return parameters.map((p) => p.id)
    }

    private scheduleListenerCreation (ids: EndpointProxyParams['id'][]): void {
        for (const id of ids) {
            this.offs.push(this.args.rstAdapter.subscribeToStatusType('endpoints', id, this.args.listener))
        }
    }

    private async chain<V> (initial: V, ...steps: ((context: ChainStepContext<V>) => void)[]): Promise<ChainContext<V>> {
        const cursor = ++this.usage
        const context = new ChainContext(initial)

        for (const executeStep of steps) {
            /**
             * Check if step is still valid
             */
            if (this.usage !== cursor) {
                context.stop()
            }

            /**
             * If it should be stopped, then get out
             */
            if (context.isStopped()) {
                break
            }

            /**
             * Execute the next async step
             */
            executeStep(context)

            /**
             * Wait for everything to be done
             */
            await context.readiness()
        }

        return context
    }

    async initialize (): Promise<void> {
        await this.chain<string[]>(
            [],
            (context) => context.setPromise(this.fetchIds()),
            (context) => this.scheduleListenerCreation(context.getValue())
        )
    }

    async update (): Promise<boolean> {
        const context = await this.chain<string[]>(
            [],
            // eslint-disable-next-line no-void
            () => void this.clearCurrentListeners(),
            (context) => context.setPromise(this.fetchIds()),
            (context) => this.scheduleListenerCreation(context.getValue())
        )

        return !context.isStopped()
    }

    async drop (): Promise<void> {
        this.killUsage()
        await this.clearCurrentListeners()
    }
}

export class VrpcResourceStateListenerEndpointProxyInstanceHandler implements EndpointsHandler {
    private readonly createRegistrationListener = createProxyEventsHandler<ResourceStateListenerProxy>('registered', 'unregistered')

    protected readonly stateUpdateThrottler: number = 150

    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_LIST

    readonly optionalFilters = ['connection' as const, 'service' as const]

    readonly requiredFilters = []

    readonly classNameFilter = PROTOCOL_MAPPER_RESOURCE_STATE_LISTENER

    readonly agent = null

    readonly ignoreInstances = null

    readonly ignoreInstanceByFilter = null

    readonly sourceInstanceName = null

    async onChange ({ listener, ...args }: EndpointsHandlerArgs['OnChangeArgs']): Promise<EndpointsHandlerArgs['OnChangeUnsub']> {
        /**
         * This droppable handles a scenario where retrieval of the endpoint ids is yielded while another
         */
        const droppable = new Droppable()

        /**
         * Throttle the triggers as there may be a lot of calls
         */
        const throttler = new Throttler(this.stateUpdateThrottler)

        let cancelTrigger: VoidFunction | null = null
        const triggerThrottledListener: VoidFunction = () => {
            cancelTrigger = throttler.run(() => droppable.ifNotDropped(listener))
        }

        const statesListener = new StateListener({ ...args, listener: triggerThrottledListener })

        /**
         * Whenever there is a change on the registration, trigger calls
         */
        const stopListeningToRegistrations = await this.createRegistrationListener({
            ...args,
            listener: () =>
                // eslint-disable-next-line no-void
                void statesListener.update().then((ran) => {
                    if (ran) {
                        triggerThrottledListener()
                    }
                }),
        })

        /**
         * Start listening to current endpoint state changes
         */
        await statesListener.initialize()

        return Promise.resolve(async (isGone) => {
            await Promise.all([droppable.drop(), stopListeningToRegistrations(isGone), statesListener.drop(), cancelTrigger?.()])
        })
    }

    // eslint-disable-next-line class-methods-use-this
    async mapToDomain ({ instance, filter, rstAdapter }: EndpointsHandlerArgs['DomainMapperArgs']): Promise<EndpointsHandlerArgs['Domain']> {
        const params = await getEndpointsParams(instance, filter)
        const mapper = new CybusEndpointMapper({}, (id) => rstAdapter.fetchStatusType('endpoints', id))
        params.forEach((param) => mapper.push(param))
        return mapper.values
    }
}
