import { Capability, type CybusServiceDataResource } from '../../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../../application'

import { createNonImplementedCapabilityError } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

export class ServicesDataResourcesStrategy extends BaseSubscriptionStrategy<'serviceResources'> {
    readonly requiredPermissions = [Capability.SERVICE_SUBSCRIPTION_METADATA]

    // eslint-disable-next-line class-methods-use-this
    override retrieveAll (filter: SubscriptionFilterArgs): Promise<CybusServiceDataResource[]> {
        throw createNonImplementedCapabilityError(Capability.SERVICE_SUBSCRIPTION_METADATA, { method: 'retrieveAll', filter })
    }
}
