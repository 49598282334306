import type { StatusType } from '../../../../../../domain'

import type { StatusSupportedType } from '../../../../../Connectware'
import { ENDPOINT_CLASSNAME_FILTER, MAPPING_CLASSNAME_FILTER, NODE_CLASSNAME_FILTER } from '../../../../constants'
import type { EndpointProxy, MappingProxy, NodeProxy } from '../../../../proxies'
import { createStatusEventsHandler, SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcInstanceToOneSubscriptionHandler } from '..'

type SupportedProxy = MappingProxy | EndpointProxy | NodeProxy
type StatusHandler<Proxy extends SupportedProxy> = VrpcInstanceToOneSubscriptionHandler<Proxy, StatusType>
type StatusHandlerArgs<Proxy extends SupportedProxy> = VrpcHandlerMappingPropertiesArgs<StatusHandler<Proxy>>

abstract class VrpcProxyInstanceStatusHandler<Proxy extends SupportedProxy> implements StatusHandler<Proxy> {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_ONE

    readonly optionalFilters = []

    readonly requiredFilters = []

    readonly ignoreInstances = null

    readonly ignoreInstanceByFilter = null

    readonly sourceInstanceName = null

    readonly agent = null

    readonly onChange = createStatusEventsHandler(this.getStatusType())

    abstract readonly classNameFilter: StatusHandler<Proxy>['classNameFilter']

    abstract getStatusType (): StatusSupportedType

    mapToDomain ({ rstAdapter, instance }: StatusHandlerArgs<Proxy>['DomainMapperArgs']): Promise<StatusHandlerArgs<Proxy>['Domain']> {
        return rstAdapter.fetchStatusType(this.getStatusType(), instance._targetId)
    }
}

export class VrpcMappingProxyInstanceStatusHandler extends VrpcProxyInstanceStatusHandler<MappingProxy> {
    override classNameFilter = MAPPING_CLASSNAME_FILTER

    override getStatusType (): StatusSupportedType {
        return 'mappingStatus'
    }
}

export class VrpcEndpointProxyInstanceStatusHandler extends VrpcProxyInstanceStatusHandler<EndpointProxy> {
    override classNameFilter = ENDPOINT_CLASSNAME_FILTER

    override getStatusType (): StatusSupportedType {
        return 'endpointStatus'
    }
}

export class VrpcNodeProxyInstanceStatusHandler extends VrpcProxyInstanceStatusHandler<NodeProxy> {
    override classNameFilter = NODE_CLASSNAME_FILTER

    override getStatusType (): StatusSupportedType {
        return 'nodeStatus'
    }
}
