import { createTimeout, executeOnce, ManagedPromise, Queue, type ReadonlyRecord } from '../../utils'
import { Capability, ConnectwareError, ConnectwareErrorType, type CybusService, type StatusType } from '../../domain'

import {
    type AuthenticatedResource,
    type BackendEventStreamResponseContent,
    type BackendPath,
    type BackendQueryParameters,
    createRSTNonDeviationsCountMapper,
    type DeviationsSubscription,
    mapRSTResourceStatusViewCurrentStatusType,
    mapRSTResourceStatusViewId,
    type ServiceDeviationSupportedType,
    type StatusSupportedType,
    type StatusTypeSubscription,
} from '../Connectware'
import { RSTWebSocketWrapper, type RSTWebSocketWrapperOptions, type RSTWrappedWebSocket } from './Wrapper'

type RSTPaths = BackendPath<'resource-status-tracking'>
type Request<Type, Id> = Readonly<{ type: Type, id: Id, handler: VoidFunction, promise: ManagedPromise<VoidFunction, ConnectwareError> }>

abstract class SocketsHelper<Type extends string, Id extends string, Path extends RSTPaths, Cache> {
    /**
     * The paths to the endpoint to be used for sockets
     */
    abstract readonly paths: ReadonlyRecord<Type, AuthenticatedResource<Path> | null>

    /**
     * The pending requests that need to be fetched
     */
    private readonly requests: Request<Type, Id>[] = []

    /**
     * The results that are cached for up to the lifespan
     * In case they are needed to be retrieved
     * @see {options['cacheLifespan']}
     *
     * Why is this cache wrapped in a another object?
     * So the cache clearing comparisons does **not**
     * clear previous value by mistake if its a primitive value
     */
    private readonly cachedResults = new Map<`${Type}_${Id}`, ReadonlyRecord<'value', Cache | ConnectwareError>>()

    /**
     * Sockets can be disconnected and reconnected as they are in use or disuse
     * This objects holds all of them for re-use
     */
    private readonly sockets = new Map<string, RSTWrappedWebSocket<Path>>()

    constructor (private readonly options: ReadonlyRecord<'cacheLifespan' | 'pageSize', number>) {}

    abstract mapQueryParameters (path: Type, id: Id[]): BackendQueryParameters<Path> & ReadonlyRecord<string, string | string[]>

    abstract mapIdFromData (type: Type, args: BackendEventStreamResponseContent<Path>): Id

    abstract mapCache (type: Type, data: BackendEventStreamResponseContent<Path>): Cache

    getSocket (id: Id): RSTWrappedWebSocket<Path> | null {
        return this.sockets.get(id) ?? null
    }

    setSocket (id: Id, socket: RSTWrappedWebSocket<Path>): void {
        this.sockets.set(id, socket)
    }

    addChangeHandlerRequest (type: Type, id: Id, handler: VoidFunction): Promise<VoidFunction> {
        const promise = new ManagedPromise<VoidFunction, ConnectwareError>()

        this.requests.push({ type, id, handler, promise })

        return promise.promise
    }

    extractNextRequests (): Readonly<{ type: Type, requests: Request<Type, Id>[] }> | null {
        const [first, ...next] = this.requests.splice(0, this.requests.length)

        if (!first) {
            /** There is nothing here, just give up */
            return null
        }

        const { type } = first
        const nextRequests = [first]

        next.forEach((request) => {
            if (request.type === type && nextRequests.length < this.options.pageSize) {
                nextRequests.push(request)
            } else {
                this.requests.push(request)
            }
        })

        return { type, requests: nextRequests }
    }

    setCache (type: Type, dataOrError: Readonly<{ data: BackendEventStreamResponseContent<Path> }> | Readonly<{ id: Id, error: ConnectwareError }>): void {
        const isError = 'error' in dataOrError

        const key = `${type}_${isError ? dataOrError.id : this.mapIdFromData(type, dataOrError.data)}` as const
        const cache = { value: isError ? dataOrError.error : this.mapCache(type, dataOrError.data) }

        this.cachedResults.set(key, cache)

        createTimeout(() => {
            if (this.cachedResults.get(key) === cache) {
                this.cachedResults.delete(key)
            }
        }, this.options.cacheLifespan)
    }

    getCached (type: Type, id: Id): Cache | ConnectwareError | null {
        return this.cachedResults.get(`${type}_${id}`)?.value ?? null
    }
}

export type RSTBaseSubscriptionOptions = Pick<RSTWebSocketWrapperOptions<RSTPaths>, 'origin' | 'pingInterval'> &
    ReadonlyRecord<'cacheLifespan' | 'pageSize', number>

abstract class RSTBaseSubscription {
    private readonly queue = new Queue()

    protected readonly SocketWrapper: new <Path extends RSTPaths>(options: RSTWebSocketWrapperOptions<Path>) => RSTWrappedWebSocket<Path> = RSTWebSocketWrapper

    constructor (protected readonly options: RSTBaseSubscriptionOptions) {}

    private resolveRequests<Type extends string, Id extends string, Path extends RSTPaths, Cache> (helper: SocketsHelper<Type, Id, Path, Cache>): Promise<void> {
        return this.queue.push(async () => {
            const toBeResolved = helper.extractNextRequests()

            if (toBeResolved === null) {
                /** Nothing to resolve */
                return
            }

            const { requests, type } = toBeResolved
            const pathConfiguration = helper.paths[type]
            if (!pathConfiguration) {
                requests.forEach(({ promise, type, id }) =>
                    promise.reject(new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Subscription not supported', { type, id }))
                )
                return
            }

            /**
             * Create new wrapper for the ids that do not have a socket wrapper form themselves
             */
            const createNewWrapper = executeOnce(() => {
                const { origin, pingInterval } = this.options
                const { path } = pathConfiguration

                const ids = requests.reduce((allIds, request) => (helper.getSocket(request.id) ? allIds : allIds.add(request.id)), new Set<Id>())
                return new this.SocketWrapper({ origin, path, params: helper.mapQueryParameters(type, Array.from(ids)), pingInterval })
            })

            /**
             * Group requests by a WebSocket that is equipped to fetch
             * the particular information as requested
             *
             * If a websocket does not exist then create a new socket
             * All new sockets will contain the ids in a batch,
             * meaning that one socket can work for multiple requested resources
             */
            const requestsGroupedBySockets = requests.reduce((r, request) => {
                /** Get the formely created wrapped sockets */
                let wrapper = helper.getSocket(request.id)
                if (!wrapper) {
                    wrapper = createNewWrapper()
                    helper.setSocket(request.id, wrapper)
                }
                return r.set(wrapper, [...(r.get(wrapper) ?? []), request])
            }, new Map<RSTWrappedWebSocket<Path>, Request<Type, Id>[]>())

            /**
             * Processes each group of requests associated with a WebSocket
             * This handles the real-time data updates efficiently
             */
            requestsGroupedBySockets.forEach((requests, socket) => {
                /** Map of handlers indexed by request Id for easy access later */
                const handlers = requests.reduce((r, request) => r.set(request.id, request.handler), new Map<string, Request<Type, Id>['handler']>())
                /**
                 * Listen for messages from the WebSocket,
                 * process the data
                 * and call the appropriate handler
                 */
                socket
                    .on((data) => {
                        if (ConnectwareError.is(data)) {
                            requests.forEach(({ id, handler }) => {
                                helper.setCache(type, { id, error: data })
                                handler()
                            })
                        } else {
                            const id = helper.mapIdFromData(type, data)
                            const handler = handlers.get(id)

                            if (handler) {
                                helper.setCache(type, { data })
                                handler()
                            }
                        }
                    })
                    .then((off) => {
                        /**
                         * Once socket connection is established
                         * Wait for the usage of it to drop off
                         * And remove handlers that are no longer needed
                         */
                        requests.forEach(({ id, promise }) =>
                            promise.resolve(() => {
                                /** For each request that no longer wants to listen, register the disuse */
                                handlers.delete(id)

                                if (handlers.size === 0) {
                                    /** Let the socket know it no longer needs to listen */
                                    off()
                                }
                            })
                        )
                    })
                    /** Handles any exceptions with setting up the socket connection */
                    .catch((e: ConnectwareError) => requests.forEach(({ promise }) => promise.reject(e)))
            })
        })
    }

    protected subscribe<Type extends string, Id extends string, Path extends RSTPaths, Cache> (
        helper: SocketsHelper<Type, Id, Path, Cache>,
        type: Type,
        id: Id,
        handler: VoidFunction
    ): Promise<VoidFunction> {
        const offPromise = helper.addChangeHandlerRequest(type, id, handler)

        void this.resolveRequests(helper)

        return offPromise
    }
}

class StateSocketsHelper extends SocketsHelper<StatusSupportedType, string, '/api/v2/resources/states', StatusType> {
    readonly paths: ReadonlyRecord<StatusSupportedType, AuthenticatedResource<'/api/v2/resources/states'> | null> = {
        agents: null,
        connection: { path: '/api/v2/resources/states', capability: Capability.CONNECTION_READ },
        connections: { path: '/api/v2/resources/states', capability: Capability.CONNECTIONS_READ },
        endpoint: { path: '/api/v2/resources/states', capability: Capability.ENDPOINT_READ },
        endpoints: { path: '/api/v2/resources/states', capability: Capability.ENDPOINTS_READ },
        endpointStatus: { path: '/api/v2/resources/states', capability: Capability.ENDPOINT_STATE_READ },
        mapping: { path: '/api/v2/resources/states', capability: Capability.MAPPING_READ },
        mappings: { path: '/api/v2/resources/states', capability: Capability.MAPPINGS_READ },
        mappingStatus: { path: '/api/v2/resources/states', capability: Capability.MAPPING_STATE_READ },
        nodes: { path: '/api/v2/resources/states', capability: Capability.NODES_READ },
        nodeStatus: { path: '/api/v2/resources/states', capability: Capability.NODE_STATE_READ },
        server: { path: '/api/v2/resources/states', capability: Capability.SERVER_READ },
        servers: { path: '/api/v2/resources/states', capability: Capability.SERVERS_READ },
    }

    mapQueryParameters (_: StatusSupportedType, ids: string[]): BackendQueryParameters<'/api/v2/resources/states'> {
        return { resourceIds: ids }
    }

    mapIdFromData (_: StatusSupportedType, data: BackendEventStreamResponseContent<'/api/v2/resources/states'>): string {
        return mapRSTResourceStatusViewId(data.resource)
    }

    mapCache (_: StatusSupportedType, data: BackendEventStreamResponseContent<'/api/v2/resources/states'>): StatusType {
        return mapRSTResourceStatusViewCurrentStatusType(data.state)
    }
}

export class RSTStatusTypeSubscription extends RSTBaseSubscription implements StatusTypeSubscription {
    private readonly stateHelper = new StateSocketsHelper(this.options)

    subscribeToStatusType (type: StatusSupportedType, id: string, handler: VoidFunction): Promise<VoidFunction> {
        return this.subscribe(this.stateHelper, type, id, handler)
    }

    useCachedStatusType (type: StatusSupportedType, id: string): StatusType | ConnectwareError | null {
        return this.stateHelper.getCached(type, id)
    }
}

class IsDeviatedSocketsHelper extends SocketsHelper<ServiceDeviationSupportedType, string, '/api/v2/resources/states/deviations/count', boolean> {
    private static readonly mapDeviationCount = createRSTNonDeviationsCountMapper<
        BackendEventStreamResponseContent<'/api/v2/resources/states/deviations/count'>,
        CybusService['id']
    >(({ serviceId }) => serviceId)

    private static readonly mapDeviationCountCache = createRSTNonDeviationsCountMapper<
        BackendEventStreamResponseContent<'/api/v2/resources/states/deviations/count'>,
        boolean
    >(({ count }) => Boolean(count))

    readonly paths: ReadonlyRecord<ServiceDeviationSupportedType, AuthenticatedResource<'/api/v2/resources/states/deviations/count'>> = {
        service: { path: '/api/v2/resources/states/deviations/count', capability: Capability.SERVICE_READ },
        serviceDeviations: { path: '/api/v2/resources/states/deviations/count', capability: Capability.SERVICE_DEVIATIONS_READ },
        serviceResources: { path: '/api/v2/resources/states/deviations/count', capability: Capability.SERVICE_SUBSCRIPTION_METADATA },
        services: { path: '/api/v2/resources/states/deviations/count', capability: Capability.SERVICES_READ },
        servicesLinks: { path: '/api/v2/resources/states/deviations/count', capability: Capability.SERVICES_READ },
    }

    mapQueryParameters (_: ServiceDeviationSupportedType, ids: CybusService['id'][]): BackendQueryParameters<'/api/v2/resources/states/deviations/count'> {
        return { ids }
    }

    mapIdFromData (_: ServiceDeviationSupportedType, args: BackendEventStreamResponseContent<'/api/v2/resources/states/deviations/count'>): string {
        return IsDeviatedSocketsHelper.mapDeviationCount(args)
    }

    mapCache (_: ServiceDeviationSupportedType, data: BackendEventStreamResponseContent<'/api/v2/resources/states/deviations/count'>): boolean {
        return IsDeviatedSocketsHelper.mapDeviationCountCache(data)
    }
}

export class RSTDeviationsSubscription extends RSTBaseSubscription implements DeviationsSubscription {
    private readonly isDeviatedHelper = new IsDeviatedSocketsHelper(this.options)

    useCachedIsDeviated (type: ServiceDeviationSupportedType, serviceId: CybusService['id']): boolean | ConnectwareError | null {
        return this.isDeviatedHelper.getCached(type, serviceId)
    }

    subscribeToServiceDeviationCount (type: ServiceDeviationSupportedType, serviceId: CybusService['id'], handler: VoidFunction): Promise<VoidFunction> {
        return this.subscribe(this.isDeviatedHelper, type, serviceId, handler)
    }
}
