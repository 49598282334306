import type { SubscriptionsTypes } from '../../../../../../application'

import { CybusServiceDeviationMapper, SERVICE_AGENT } from '../../../../../Connectware'
import { SERVICE_CLASSNAME } from '../../../../constants'
import type { ServiceProxy } from '../../../../proxies'
import { createDeviationsEventsHandler, SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcInstanceToListSubscriptionHandler } from '..'

type DeviationsHandler = VrpcInstanceToListSubscriptionHandler<ServiceProxy, SubscriptionsTypes['serviceDeviations']>
type DeviationsHandlerArgs = VrpcHandlerMappingPropertiesArgs<DeviationsHandler>

export class VrpcServiceProxyInstanceToNotificationHandler implements DeviationsHandler {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_LIST

    readonly optionalFilters = []

    readonly requiredFilters = []

    readonly classNameFilter = SERVICE_CLASSNAME

    readonly agent = SERVICE_AGENT

    readonly ignoreInstances = null

    readonly ignoreInstanceByFilter = null

    readonly sourceInstanceName = null

    readonly onChange = createDeviationsEventsHandler('serviceDeviations')

    // eslint-disable-next-line class-methods-use-this
    mapToDomain ({ instance, rstAdapter }: DeviationsHandlerArgs['DomainMapperArgs']): Promise<DeviationsHandlerArgs['Domain']> {
        const mapper = new CybusServiceDeviationMapper((id) => rstAdapter.fetchIsDeviated('serviceDeviations', id))
        mapper.push(instance._targetId)
        return mapper.values
    }
}
