import type { CybusDetailedConnection, StatusType } from '../../../../../../domain'

import { type ConnectionProxyParams, mapDetailedConnection, type StatusSupportedType } from '../../../../../Connectware'
import { CONNECTION_CLASSNAME_FILTER } from '../../../../constants'
import { VrpcBaseConnectionProxyInstanceHandler } from './Base'

export class VrpcDetailedConnectionProxyInstanceHandler extends VrpcBaseConnectionProxyInstanceHandler<'connection'> {
    readonly optionalFilters = []

    readonly requiredFilters = []

    readonly classNameFilter = CONNECTION_CLASSNAME_FILTER

    readonly ignoreInstances = null

    readonly ignoreInstanceByFilter = null

    protected override getStatusSupportedType (): StatusSupportedType {
        return 'connection'
    }

    protected override mapConnection (connection: ConnectionProxyParams, status: StatusType): CybusDetailedConnection {
        return { ...mapDetailedConnection(connection), status }
    }
}
