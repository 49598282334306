import type { ReadonlyRecord } from '../../../utils'
import { CybusPermissionContext } from '../../../domain'

import type { PermissionContext } from '..'

const contextsBackendMap: ReadonlyRecord<PermissionContext, CybusPermissionContext> = {
    control_plane: CybusPermissionContext.CONTROL_PLANE,
    http: CybusPermissionContext.HTTP,
    mqtt: CybusPermissionContext.MQTT,
}
const contextsFrontendMap: ReadonlyRecord<CybusPermissionContext, PermissionContext> = {
    [CybusPermissionContext.CONTROL_PLANE]: 'control_plane',
    [CybusPermissionContext.HTTP]: 'http',
    [CybusPermissionContext.MQTT]: 'mqtt',
}

export const mapContextToPermissionContext = (context: PermissionContext): CybusPermissionContext => contextsBackendMap[context]
export const mapPermissionContextToContext = (context: CybusPermissionContext): PermissionContext => contextsFrontendMap[context]
