import type { ReadonlyRecord } from '../../../utils'

import type { MappingProxyParams, PermissionContext } from '..'

/**
 * @todo generate this type from BE images
 * @see https://cybusio.atlassian.net/browse/CC-2690
 * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/service-manager/src/Service.js#lines-224
 * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/service-manager/src/swagger/swagger.yaml#lines-140
 */
export type CybusServiceProxyInfo = Readonly<{
    id: string
    state: string
    name: string
    version?: string
}>

export type CybusServiceDependencies = ReadonlyRecord<'dependsOn' | 'dependent', string[]>

export type CybusRolePermissionConfigurationResponse = Readonly<{ resource: string, operation: 'read' | 'readWrite' | 'write', context: PermissionContext }>

type BaseCommissioningDataParameter<TypeName, Type, Extra = ReadonlyRecord<never, never>> = Readonly<{
    type: TypeName
    default?: Type
    description?: string
    enum?: Type[]
}> &
    Readonly<Extra>

/**
 * @see https://docs.cybus.io/1-7-3/documentation/services/structure-of-commissioning-files/parameters#parameter-properties
 */
type CommissioningDataParameter =
    | BaseCommissioningDataParameter<
          'string',
          string,
          { minLength?: number, maxLength?: number, pattern?: string, format?: 'date' | 'date-time' | 'uri' | 'email' | 'hostname' | 'ipv4' | 'ipv6' | 'regex' }
      >
    | BaseCommissioningDataParameter<'number' | 'integer', number, { minimum?: number, maximum?: number }>
    | BaseCommissioningDataParameter<'boolean', boolean>
    | BaseCommissioningDataParameter<
          'array',
          (string | number | boolean)[],
          {
              items: { type: string | number | boolean } | { type: string | number | boolean }[]
              minItems?: number
              maxItems?: number
              uniqueItems?: boolean
          }
      >

/**
 * @see https://docs.cybus.io/1-7-3/documentation/services/structure-of-commissioning-files/parameters#parameter-properties
 */
type CybusServiceParameter = string | number | boolean | (string | number)[]

/**
 * @see https://docs.cybus.io/1-7-3/documentation/services/structure-of-commissioning-files/metadata
 */
type CommissioningDataMetadata = Readonly<{ name: string, icon?: string, provider?: string, homepage?: string, version?: string }>

/**
 * @see https://docs.cybus.io/1-7-3/documentation/services/structure-of-commissioning-files
 */
export type CommissioningData = Readonly<{
    /**
     * @see https://docs.cybus.io/1-7-3/documentation/services/structure-of-commissioning-files/metadata#version
     */
    version?: 1

    /**
     * @see https://docs.cybus.io/1-7-3/documentation/services/structure-of-commissioning-files/description
     */
    description: string
    metadata: CommissioningDataMetadata
    parameters?: ReadonlyRecord<string, CommissioningDataParameter>
    /**
     * @see https://docs.cybus.io/1-7-3/documentation/services/structure-of-commissioning-files/definitions
     * @todo finish mapping properties
     */
    definitions?: ReadonlyRecord<string, unknown>
    resources: ReadonlyRecord<string, CommissioningDataResource>
}>

type CommissioningDataBaseResource<T extends string, P> = Readonly<P extends undefined | void ? { type: T } : { type: T, properties: P }>

/**
 * @todo generate this type from BE images
 * @see https://cybusio.atlassian.net/browse/CC-2690
 * @see https://bitbucket.org/cybusio/cybus/src/29e383e0e28ac44ae442365e6b8d271475b33617/service-manager/src/swagger/swagger.yaml#lines-419
 */
export type ServicePostRequest = Readonly<{
    marketplace?: Readonly<{ filename: string, directory: string, version: string, updatedAt: string }>
    commissioningFile: string
    parameters: ReadonlyRecord<string, CybusServiceParameter>
}>

/**
 * @todo generate this type from BE images
 * @see https://cybusio.atlassian.net/browse/CC-2690
 * @see https://bitbucket.org/cybusio/cybus/src/07ab1fe1b0481762de32ae848cdd52637e301c5e/service-manager/src/swagger/swagger.yaml#lines-548
 */
export type ValidationError = Readonly<{ message: string }>

/**
 * @todo generate this type from BE images
 * @see https://cybusio.atlassian.net/browse/CC-2690
 * @see https://bitbucket.org/cybusio/cybus/src/5f7fd79266ba5b476fa5ce6df87640bab6850db2/service-manager/src/swagger/swagger.yaml#lines-444
 */
export type AllSchemasResponse = ReadonlyRecord<
    | 'baseSchema'
    | 'CybusRule'
    /** Connections */
    | 'connectionSchema'
    | 'AdsConnection'
    | 'BacnetConnection'
    | 'EthernetIpConnection'
    | 'FocasConnection'
    | 'GenericVrpcConnection'
    | 'HbmdaqConnection'
    | 'HeidenhainConnection'
    | 'HttpConnection'
    | 'InfluxdbConnection'
    | 'KafkaConnection'
    | 'ModbusConnection'
    | 'MqttConnection'
    | 'MssqlConnection'
    | 'OpcdaConnection'
    | 'OpcuaConnection'
    | 'ProfinetConnection'
    | 'S7Connection'
    | 'ShdrConnection'
    | 'SinumerikConnection'
    | 'SopasConnection'
    | 'SqlConnection'
    | 'SystemstateConnection'
    | 'WermaConnection'
    /** Endpoints */
    | 'endpointSchema'
    | 'AdsEndpoint'
    | 'BacnetEndpoint'
    | 'EthernetIpEndpoint'
    | 'FocasEndpoint'
    | 'GenericVrpcEndpoint'
    | 'HbmdaqEndpoint'
    | 'HeidenhainEndpoint'
    | 'HttpEndpoint'
    | 'InfluxdbEndpoint'
    | 'KafkaEndpoint'
    | 'ModbusEndpoint'
    | 'MqttEndpoint'
    | 'MssqlEndpoint'
    | 'OpcdaEndpoint'
    | 'OpcuaEndpoint'
    | 'OpcuaNode'
    | 'OpcuaObjectNode'
    | 'OpcuaReferenceNode'
    | 'OpcuaServer'
    | 'ProfinetEndpoint'
    | 'S7Endpoint'
    | 'ShdrEndpoint'
    | 'SinumerikEndpoint'
    | 'SopasEndpoint'
    | 'SqlEndpoint'
    | 'SystemstateEndpoint'
    | 'TcpEndpoint'
    | 'WermaEndpoint',
    unknown
>

/**
 * @todo generate this type from BE images
 * @see https://cybusio.atlassian.net/browse/CC-2690
 * @see https://bitbucket.org/cybusio/cybus/src/29e383e0e28ac44ae442365e6b8d271475b33617/service-manager/src/swagger/swagger.yaml#lines-440
 */
export type UpdateServiceRequest = ServicePostRequest

export type CommissioningDataResourceType = CommissioningDataResource['type']

type BaseCybusConnectionProperties<P extends string, C> = Readonly<{
    protocol: P
    targetState?: 'connected' | 'disconnected'
    agentName: string
    connection: C
}>

/**
 * @see /docs/user/services/structure/resources.html
 */
type CommissioningDataResource =
    /**
     * @see https://docs.cybus.io/1-7-3/documentation/services/structure-of-commissioning-files/resources/cybus-connection
     * @todo the connection has **too** many multiple configurations
     * this will need to be updated in the future
     */
    | CommissioningDataBaseResource<
          'Cybus::Connection',
          | BaseCybusConnectionProperties<string, ReadonlyRecord<string, unknown>>
          | BaseCybusConnectionProperties<
                'Ads',
                Readonly<{ localAddress: string, localPort: number, host: string, routerTcpPort?: number, port: number, netId: string }>
            >
          | BaseCybusConnectionProperties<
                'Bacnet',
                Readonly<{
                    /** @see https://docs.cybus.io/1-7-3/documentation/industry-protocol-details/bacnet/bacnetconnection#deviceaddress-string-required */
                    deviceAddress: string
                    /** @see https://docs.cybus.io/1-7-3/documentation/industry-protocol-details/bacnet/bacnetconnection#deviceinstance-integer-required */
                    deviceInstance: number
                }>
            >
          | BaseCybusConnectionProperties<'GenericVrpc', Readonly<{ vrpc: Readonly<{ agent: string, domain: string, className: string, args: unknown[] }> }>>
          | BaseCybusConnectionProperties<'Hbmdaq', Readonly<{ host: string, port: number }>>
          | BaseCybusConnectionProperties<
                'Heidenhain',
                Readonly<{
                    agent: string
                    ipAddress?: string
                    cncType?: 'tnc640' | 'itnc530' | 'tnc426'
                    domain: string
                    tablePassword: string
                    usrPassword: string
                    sysPassword: string
                }>
            >
          | BaseCybusConnectionProperties<
                'Http',
                Readonly<{
                    scheme?: 'http' | 'https'
                    host: string
                    port: number
                    prefix?: string
                    headers?: ReadonlyRecord<string, string>
                    /**
                     * @todo finish documentation https://docs.cybus.io/1-7-3/documentation/industry-protocol-details/http-rest/httpconnection#auth-object
                     */
                    auth?: unknown
                }>
            >
          | BaseCybusConnectionProperties<'Modbus', Readonly<{ host: string, port: number, unitId?: number, socketTimeout?: number }>>
          | BaseCybusConnectionProperties<'Profinet', Readonly<{ localInterface: string, gsdPath?: string, configPath?: string }>>
          /**
           * @todo finish documentation protocol-mapper/src/protocols/kafka/KafkaConnection.json
           */
          | BaseCybusConnectionProperties<'Kafka', Readonly<{ brokers: string[] }>>
          | BaseCybusConnectionProperties<
                'Influxdb',
                Readonly<{
                    host: string
                    port: number
                    bucket: 'generic' | string
                    scheme: 'http' | string
                    flushInterval: number
                    token: string
                    probeInterval: number
                    timeout: number
                    transportOptions: ReadonlyRecord<never, never>
                    org: 'generic' | string
                    precision: 'ms' | string
                    maxRetryDelay: number
                    minRetryDelay: number
                    retryJitter: number
                    maxBufferLines: number
                    maxRetries: number
                    exponentialBase: number
                    batchSize: number
                }>
            >
          | BaseCybusConnectionProperties<
                'EthernetIp' | 'Focas' | 'Mqtt' | 'Mssql' | 'Opcda' | 'Opcua' | 'S7' | 'Shdr' | 'Sinumerik' | 'Sopas' | 'Sql' | 'Systemstate' | 'Werma',
                Readonly<{ host?: unknown, port?: unknown }>
            >
      >
    /**
     * @see https://docs.cybus.io/documentation/services/structure-of-commissioning-files/resources/cybus-endpoint#protocol
     * @todo finish mapping properties
     */
    | CommissioningDataBaseResource<
          'Cybus::Endpoint',
          {
              connection: string
              protocol:
                  | 'Ads'
                  | 'Bacnet'
                  | 'EthernetIp'
                  | 'GenericVrpc'
                  | 'Hbmdaq'
                  | 'Heidenhain'
                  | 'Http'
                  | 'Influxdb'
                  | 'Modbus'
                  | 'Mqtt'
                  | 'Mssql'
                  | 'Opcda'
                  | 'Opcua'
                  | 'Profinet'
                  | 'S7'
                  | 'Shdr'
                  | 'Sinumerik'
                  | 'Sopas'
                  | 'Sql'
                  | 'Werma'
                  | string
              subscribe?: unknown
              read?: unknown
              write?: unknown
              agentName?: string
              qos?: number
              retain?: boolean
              targetState?: string
          }
      >
    /**
     * @see https://docs.cybus.io/1-7-3/documentation/services/structure-of-commissioning-files/resources/cybus-mapping
     */
    | CommissioningDataBaseResource<
          'Cybus::Mapping',
          Readonly<{ mappings: MappingProxyParams['mappings'], agentName?: MappingProxyParams['agentName'], targetState?: MappingProxyParams['targetState'] }>
      >

    /**
     * @see https://docs.cybus.io/1-7-3/documentation/services/structure-of-commissioning-files/resources/cybus-container
     */
    | CommissioningDataBaseResource<
          'Cybus::Container',
          Readonly<{
              capAdd?: (
                  | 'ALL'
                  | 'AUDIT_CONTROL'
                  | 'AUDIT_WRITE'
                  | 'CHOWN'
                  | 'DAC_OVERRIDE'
                  | 'DAC_READ_SEARCH'
                  | 'FOWNER'
                  | 'FSETID'
                  | 'IPC_LOCK'
                  | 'IPC_OWNER'
                  | 'KILL'
                  | 'LEASE'
                  | 'LINUX_IMMUTABLE'
                  | 'MAC_ADMIN'
                  | 'MAC_OVERRIDE'
                  | 'MKNOD'
                  | 'NET_ADMIN'
                  | 'NET_BIND_SERVICE'
                  | 'NET_BROADCAST'
                  | 'NET_RAW'
                  | 'SETFCAP'
                  | 'SETGID'
                  | 'SETPCAP'
                  | 'SETUID'
                  | 'SYSLOG'
                  | 'SYS_ADMIN'
                  | 'SYS_BOOT'
                  | 'SYS_CHROOT'
                  | 'SYS_MODULE'
                  | 'SYS_NICE'
                  | 'SYS_PACCT'
                  | 'SYS_PTRACE'
                  | 'SYS_RAWIO'
                  | 'SYS_RESOURCE'
                  | 'SYS_TIME'
                  | 'SYS_TTY_CONFIG'
                  | 'WAKE_ALARM'
              )[]
              command?: string[]
              cpus?: number
              devices?: Readonly<{ PathOnHost: string, PathInContainer: string, CgroupPermissions: string }>[]
              entrypoint?: string[]
              environment?: ReadonlyRecord<string, number | boolean | string>
              image: string
              /**
               * @todo finish definition
               */
              labels?: unknown
              memory?: number
              ports?: string[]
              privileged?: boolean
              restart?: 'no' | 'always' | 'on-failure' | 'unless-stopped'
              volumes?: string[]
              workingDir?: string
              ulimit?: number
          }>
      >
    /**
     * @see https://docs.cybus.io/1-7-3/documentation/services/structure-of-commissioning-files/resources/cybus-link
     */
    | CommissioningDataBaseResource<'Cybus::Link', Readonly<{ name: string, href: string, ingressRoute?: string }>>
    /**
     *  @see https://docs.cybus.io/1-7-3/documentation/services/structure-of-commissioning-files/resources/cybus-ingressroute
     */
    | CommissioningDataBaseResource<
          'Cybus::IngressRoute',
          /** An ingress route has base properties */
          Readonly<{ container: string, target?: { path?: string, port?: number } }> &
              (
                  | /** Http only properties */
                  Readonly<{ type?: 'http', slug: string }>
                  /** And tcp only properties */
                  | Readonly<{ type: 'tcp', containerPort: number, connectwarePort: number }>
              )
      >
    /**
     * @see https://docs.cybus.io/1-7-3/documentation/services/structure-of-commissioning-files/resources/cybus-user
     */
    | CommissioningDataBaseResource<'Cybus::User', Readonly<{ password: string, permissions?: CybusRolePermissionConfigurationResponse[], roles?: string[] }>>
    /**
     * @see https://docs.cybus.io/1-7-3/documentation/services/structure-of-commissioning-files/resources/cybus-role?fallback=true
     */
    | CommissioningDataBaseResource<'Cybus::Role', Readonly<{ permissions: CybusRolePermissionConfigurationResponse[] }>>
    /**
     * @see https://docs.cybus.io/1-7-3/documentation/services/structure-of-commissioning-files/resources/cybus-volume
     * @todo finish mapping properties
     */
    | CommissioningDataBaseResource<'Cybus::Volume', undefined>
    /**
     * @see https://docs.cybus.io/1-7-3/documentation/services/structure-of-commissioning-files/resources/cybus-server
     * @todo finish mapping properties
     */
    | CommissioningDataBaseResource<'Cybus::Server', undefined>

    /**
     * @see https://docs.cybus.io/1-7-3/documentation/industry-protocol-details/opc-ua/opc-ua-server
     * @todo finish mapping properties
     */
    | CommissioningDataBaseResource<'Cybus::Server::Opcua', Readonly<{ port?: number, allowAnonymous?: boolean }> | undefined>

    /**
     * @see https://docs.cybus.io/1-7-3/documentation/industry-protocol-details/http-server/httpserver
     * @todo finish mapping properties
     */
    | CommissioningDataBaseResource<'Cybus::Server::Http', Readonly<{ basePath?: string }> | undefined>
    /**
     * @see /docs/user/services/structure/resources/node.html
     * @todo finish mapping properties
     */
    | CommissioningDataBaseResource<'Cybus::Node', undefined>

export type CommissioningDataResourceProperties<T extends CommissioningDataResource['type']> = 'properties' extends keyof Extract<
    CommissioningDataResource,
    CommissioningDataBaseResource<T, unknown>
>
    ? Extract<CommissioningDataResource, CommissioningDataBaseResource<T, unknown>>['properties']
    : never

export type CybusServiceMarketPlace = Readonly<{ directory?: string, filename?: string, updatedAt?: string, version?: string }>

type BaseCreatedCommissioningResource<T, I = string, P = ReadonlyRecord<never, never>> = Readonly<{ physicalId: I, type: T, creationOrder?: number }> & P

/**
 * @todo finish mapping
 * @see https://bitbucket.org/cybusio/cybus/src/c70a24a3b906cde9f7d530dd24fd5c138084e658/service-manager/src/Service.js#service-manager/src/Service.js-1073
 */
type CreatedCommissioningResource =
    | BaseCreatedCommissioningResource<'Cybus::Role'>
    | BaseCreatedCommissioningResource<'Cybus::User'>
    | BaseCreatedCommissioningResource<'Cybus::Container'>
    | BaseCreatedCommissioningResource<'Cybus::Volume'>
    | BaseCreatedCommissioningResource<'Cybus::Link', Readonly<{ name: string, href: string, id: string }>>
    | BaseCreatedCommissioningResource<
          'Cybus::IngressRoute',
          Readonly<
              { id: string, containerHost: string, containerPort: number } & (
                  | { type: 'http', ingressUrlPrefix: string, containerPrefixRewrite: string }
                  | { type: 'tcp', ingressPort: number }
              )
          >
      >
    | BaseCreatedCommissioningResource<'Cybus::Connection', string, { agent: string, className: string }>
    | BaseCreatedCommissioningResource<'Cybus::Endpoint', string, { agent: string, className: string }>
    | BaseCreatedCommissioningResource<'Cybus::Mapping', string, { agent: string, className: string }>
    | BaseCreatedCommissioningResource<'Cybus::Mapping', string, { agent: string, className: string }>
    | BaseCreatedCommissioningResource<'Cybus::Server::Http', string, { agent: string, creationOrder: number }>
    | BaseCreatedCommissioningResource<'Cybus::Node::Http', string, { agent: string, className: string, creationOrder: number }>

export type CreatedCommissioningResourceProperties<T extends CreatedCommissioningResource['type']> = Extract<
    CreatedCommissioningResource,
    BaseCreatedCommissioningResource<T, unknown>
>

/**
 * @todo finish mapping properties
 * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/service-manager/src/Service.js#lines-206
 */
export type CybusServiceProxyParams = Readonly<{
    id: string
    commissioningData: CommissioningData
    resources: ReadonlyRecord<string, CreatedCommissioningResource>
    /**
     * Enconded in base64
     */
    commissioningFile: string
    parameters: ReadonlyRecord<string, CybusServiceParameter> & Readonly<{ id: string }>
    marketplace: CybusServiceMarketPlace
    currentState: string
    targetState: string
}>
