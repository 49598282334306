import { areDetailedCybusEndpointEquals, Capability, type CybusDetailedEndpoint } from '../../../../../../domain'

import { type BackendJsonResponseContent, type EndpointProxyParams, mapDetailedEndpoint } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

export class DetailedEndpointStrategy extends BaseSubscriptionStrategy<'endpoint'> {
    readonly requiredPermissions = [Capability.ENDPOINT_READ]

    protected override readonly areEquals = areDetailedCybusEndpointEquals

    override retrieveOne (id: string): Promise<CybusDetailedEndpoint> {
        return Promise.all([
            this.retrieve({
                capability: Capability.ENDPOINT_READ,
                method: 'GET',
                path: '/api/endpoints/+',
                pathParams: [id],
                mapper: (data: EndpointProxyParams) => data,
                handleNotFound: true,
            }),
            this.retrieve({
                capability: Capability.ENDPOINT_READ,
                method: 'GET',
                path: '/api/endpoints/+/topics',
                pathParams: [id],
                mapper: (data: BackendJsonResponseContent<'/api/endpoints/+/topics'>) => data,
                handleNotFound: true,
            }),
            this.rstAdapter.fetchStatusType('endpoint', id),
        ]).then(([params, topics, status]) => ({ ...mapDetailedEndpoint(params, [...topics]), status }))
    }
}
