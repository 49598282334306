import { executeOnce } from '../../../../../../utils'
import { areCybusConnectionsEquals, Capability, type CybusConnection } from '../../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../../application'

import { type ConnectionProxyParams, FilteredAsyncMapper, INVALID_CONNECTION_INSTANCE_PATTERN, mapConnection } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

class ConnectionsMapper extends FilteredAsyncMapper<ConnectionProxyParams, CybusConnection> {
    protected override map (connection: ConnectionProxyParams): Promise<CybusConnection> | null {
        const { service } = this.filter
        const mapOnce = executeOnce(() => mapConnection(connection))

        if (INVALID_CONNECTION_INSTANCE_PATTERN.test(connection.id) || (service && service !== mapOnce().service)) {
            return null
        }

        return this.fetchAux(connection.id).then((status) => ({ ...mapOnce(), status }))
    }
}

export class ConnectionsStrategy extends BaseSubscriptionStrategy<'connections'> {
    readonly requiredPermissions = [Capability.CONNECTIONS_READ]

    protected override readonly supportedFilters: (keyof SubscriptionFilterArgs)[] = ['service']

    protected override areEquals = areCybusConnectionsEquals

    override async retrieveAll ({ service }: SubscriptionFilterArgs): Promise<CybusConnection[]> {
        return this.retrieve({
            capability: Capability.CONNECTIONS_READ,
            method: 'GET',
            path: '/api/connections',
            mapper: (params: ConnectionProxyParams[]) => {
                const mapper = new ConnectionsMapper({ service }, (id) => this.rstAdapter.fetchStatusType('connections', id))

                for (const connection of params) {
                    mapper.push(connection)
                }

                return mapper.values
            },
        })
    }
}
