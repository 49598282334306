import type { RSTAdapter } from '../../../../../Connectware'

import type { ManagedVrpcRemote } from '../../../../utils'
import type { VrpcHandlerMappingPropertiesArgs, VrpcRemoteToListSubscriptionHandler } from '../../handlers'

import { BaseResourceManager } from './Base'

/**
 * Maps remotes into many
 */
export class RemoteHandlerManager<Domain> extends BaseResourceManager<
    VrpcRemoteToListSubscriptionHandler<Domain>,
    Readonly<{ remote: ManagedVrpcRemote, rstAdapter: RSTAdapter }>
> {
    protected readonly errorMessage = 'Error while interacting with remote'

    protected getMapToDomainArgs (): Promise<VrpcHandlerMappingPropertiesArgs<VrpcRemoteToListSubscriptionHandler<Domain>>['DomainMapperArgs']> {
        const { remote, rstAdapter } = this.aux
        return Promise.resolve({ remote, rstAdapter })
    }

    protected getOnChangeArgs (): Promise<VrpcHandlerMappingPropertiesArgs<VrpcRemoteToListSubscriptionHandler<Domain>>['OnChangeArgs']> {
        return Promise.resolve({ remote: this.aux.remote, listener: this.createValueUpdater() })
    }
}
