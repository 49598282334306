import React, { type ChangeEvent, type FC, useCallback, useEffect, useState } from 'react'
import {
    Pagination as BasePagination,
    Box,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    selectClasses,
    Stack,
    type SxProps,
    type Theme,
    Typography,
} from '@mui/material'

import { Translation } from '../../../../domain'

import { createOnEnterHandler } from '../../utils'
import { FormattedTranslation } from '../../Internationalization'
import {
    useHideJumpToPage,
    useLoading,
    usePage,
    usePageDispatcher,
    usePageSize,
    usePageSizeDispatcher,
    usePageSizeOptions,
    useShowTotalCount,
    useTotalCount,
    useTotalPages,
    useTranslations,
} from './State'

const boxStyle: SxProps<Theme> = { flex: 1 }
const totalCountStyle: SxProps<Theme> = { flex: 1, fontSize: 'body2.fontSize', color: 'grey.800' }

const jumpToPageLabelStyle: SxProps<Theme> = { mr: 1 }
const jumpToPageInputStyle: SxProps<Theme> = {
    width: 54,
    '& input': { px: 1, py: 0.5 },
    '& input[type=number]': { MozAppearance: 'textfield' },
    '& input[type=number]::-webkit-outer-spin-button': { WebkitAppearance: 'none', margin: 0 },
    '& input[type=number]::-webkit-inner-spin-button': { WebkitAppearance: 'none', margin: 0 },
}

const TotalCount: FC | null = () => {
    const totalCount = useTotalCount()
    const pageSize = usePageSize()
    const page = usePage()
    const { paginationDataType: type } = useTranslations()

    if (!(totalCount && pageSize && type)) {
        return null
    }

    const startNum = Math.max(pageSize * page + 1, 1)
    const endNum = Math.min(pageSize * (page + 1), totalCount)
    const showShort = totalCount <= pageSize

    return (
        <Box sx={totalCountStyle} id="totalCount">
            <FormattedTranslation id={Translation.TABLE_PAGINATION_DISPLAYED_ROWS} values={{ startNum, endNum, totalCount, showShort }} />{' '}
            <FormattedTranslation id={Translation[type]} values={{ count: totalCount }} />
        </Box>
    )
}

const JumpToPage: FC = () => {
    const page = usePage() + 1
    const totalPages = useTotalPages()
    const changePage = usePageDispatcher()
    const hideJumpToPage = useHideJumpToPage()

    const [currentPage, setCurrentPage] = useState<number | ''>(page)

    useEffect(() => setCurrentPage(page), [page])

    const onChange = useCallback(
        ({ target: { valueAsNumber } }: ChangeEvent<HTMLInputElement>) =>
            setCurrentPage(isNaN(valueAsNumber) ? '' : Math.min(Math.max(valueAsNumber, 1), totalPages)),
        [totalPages]
    )

    const onKeyDown = useCallback(
        createOnEnterHandler(() => {
            if (currentPage) {
                changePage(currentPage - 1)
            }
        }),
        [page, currentPage]
    )

    return (
        <Box sx={boxStyle}>
            {!hideJumpToPage && (
                <Stack direction="row" alignItems="center">
                    <Typography component="label" variant="body2" htmlFor="jumpToPage" sx={jumpToPageLabelStyle}>
                        <FormattedTranslation id={Translation.JUMP_TO_PAGE} />
                    </Typography>
                    <OutlinedInput id="jumpToPage" type="number" sx={jumpToPageInputStyle} value={currentPage} onChange={onChange} onKeyDown={onKeyDown} />
                </Stack>
            )}
        </Box>
    )
}

const LeftSidebar: FC = () => {
    const showTotalCount = useShowTotalCount()
    const { paginationDataType } = useTranslations()

    return showTotalCount && paginationDataType ? <TotalCount /> : <JumpToPage />
}

const Pagination: FC = () => {
    const totalPages = useTotalPages()

    const page = usePage()
    const changePage = usePageDispatcher()

    return <BasePagination count={totalPages} page={page + 1} size="large" onChange={(_, page) => changePage(page - 1)} />
}

const pageSizeMenuWrapperStyle: SxProps<Theme> = { display: 'flex', alignItems: 'center', px: 1, justifyContent: 'flex-end' }
const pageSizeMenuLabelStyle: SxProps<Theme> = { mr: 1, fontSize: 'body2.fontSize' }
const pageSizeMenuSelectStyle: SxProps<Theme> = { [`& .${selectClasses.select}`]: { pb: 0, fontSize: 'body2.fontSize' } }

const PageSizeMenu: FC = () => {
    const pageSize = usePageSize()
    const changePageSize = usePageSizeDispatcher()
    const pageSizeOptions = usePageSizeOptions()

    return pageSizeOptions && pageSizeOptions.length > 1 ? (
        <Box sx={[boxStyle, pageSizeMenuWrapperStyle]}>
            <InputLabel variant="standard" sx={pageSizeMenuLabelStyle}>
                <FormattedTranslation id={Translation.ROWS_PER_PAGE} />
            </InputLabel>
            <Select
                // Only here to make tests backwards compatible
                id="pagination-rows"
                sx={pageSizeMenuSelectStyle}
                size="small"
                value={pageSize}
                variant="standard"
                onChange={({ target: { value } }) => changePageSize(Number(value))}
                disableUnderline
                // Only here to make tests backwards compatible
                MenuProps={{ MenuListProps: { id: 'pagination-menu-list' } }}
            >
                {pageSizeOptions.map((value) => (
                    <MenuItem key={value} value={value}>
                        {value}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    ) : (
        <Box sx={boxStyle} />
    )
}

const cellStyle: SxProps<Theme> = {
    display: 'flex',
    py: 1,
    px: 2,
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: '1px solid',
    borderTopColor: 'divider',
}

export const Footer: FC = () => {
    const loading = useLoading()
    const totalPages = useTotalPages()
    const pageSizeOptions = usePageSizeOptions()

    /**
     * If pageSize or pageSizeOptions are falsy,
     * that means we show all data on the table,
     * and there is no need to display pagination
     */
    if (loading || !isFinite(totalPages) || pageSizeOptions === null) {
        return null
    }

    return (
        <Box sx={cellStyle}>
            <LeftSidebar />
            <Pagination />
            <PageSizeMenu />
        </Box>
    )
}
