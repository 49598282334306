import type { CybusConnection, StatusType } from '../../../../../../domain'

import { type ConnectionProxyParams, INVALID_CONNECTION_INSTANCE_PATTERN, mapConnection, type StatusSupportedType } from '../../../../../Connectware'
import { CONNECTION_CLASSNAME_FILTER } from '../../../../constants'
import { excludeInstanceByServiceName } from '..'
import { VrpcBaseConnectionProxyInstanceHandler } from './Base'

export class VrpcConnectionProxyInstanceHandler extends VrpcBaseConnectionProxyInstanceHandler<'connections'> {
    readonly optionalFilters = ['service' as const]

    readonly requiredFilters = []

    readonly classNameFilter = CONNECTION_CLASSNAME_FILTER

    // Ignore __internal__ connection
    readonly ignoreInstances = INVALID_CONNECTION_INSTANCE_PATTERN

    readonly ignoreInstanceByFilter = excludeInstanceByServiceName

    protected override getStatusSupportedType (): StatusSupportedType {
        return 'connections'
    }

    protected override mapConnection (connection: ConnectionProxyParams, status: StatusType): CybusConnection {
        return { ...mapConnection(connection), status }
    }
}
