import type { StatusType } from '../../../../../../domain'
import type { SubscriptionsTypes } from '../../../../../../application'

import type { MappingProxyParams, StatusSupportedType } from '../../../../../Connectware'
import { MAPPING_CLASSNAME_FILTER } from '../../../../constants'
import type { MappingProxy } from '../../../../proxies'

import { SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcInstanceToOneSubscriptionHandler } from '..'

type MappingHandler<T extends keyof SubscriptionsTypes> = VrpcInstanceToOneSubscriptionHandler<MappingProxy, SubscriptionsTypes[T]>
type MappingHandlerArgs<T extends keyof SubscriptionsTypes> = VrpcHandlerMappingPropertiesArgs<MappingHandler<T>>

export abstract class VrpcMappingToOneSubscriptionHandler<T extends keyof SubscriptionsTypes, FetchedInfo> implements MappingHandler<T> {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_ONE

    readonly requiredFilters = []

    readonly classNameFilter = MAPPING_CLASSNAME_FILTER

    readonly agent = null

    readonly ignoreInstances = null

    readonly sourceInstanceName = null

    abstract readonly optionalFilters: MappingHandler<T>['optionalFilters']

    abstract readonly ignoreInstanceByFilter: MappingHandler<T>['ignoreInstanceByFilter']

    /**
     * Async custom loading is done here
     */
    protected abstract fetchInformation (proxy: MappingProxy): Promise<FetchedInfo>

    /**
     * Custom mapping is done here
     */
    protected abstract mapMapping (params: MappingProxyParams, statusType: StatusType, extraInformation: FetchedInfo): SubscriptionsTypes[T]

    protected abstract getStatusSupportedType (): StatusSupportedType

    abstract onChange (args: MappingHandlerArgs<T>['OnChangeArgs']): Promise<MappingHandlerArgs<T>['OnChangeUnsub']>

    mapToDomain ({ instance, rstAdapter }: MappingHandlerArgs<T>['DomainMapperArgs']): Promise<MappingHandlerArgs<T>['Domain']> {
        return Promise.all([
            instance.getParams(),
            rstAdapter.fetchStatusType(this.getStatusSupportedType(), instance._targetId),
            this.fetchInformation(instance),
        ]).then(([params, statusType, info]) => this.mapMapping(params, statusType, info))
    }
}
