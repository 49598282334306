import type { CybusNode, StatusType } from '../../../domain'

import { mapResourceNames, type NodeProxyParams } from '..'
import { FilteredAsyncMapper } from './Async'

const mapCybusNode = ({ id, parentId, operation, protocol }: NodeProxyParams, status: StatusType): CybusNode => {
    const [service, name] = mapResourceNames(id)

    return { id, service, name, parent: parentId, operation: operation || null, protocol, status }
}

export class CybusNodeMapper extends FilteredAsyncMapper<NodeProxyParams, CybusNode> {
    protected map (params: NodeProxyParams): Promise<CybusNode> | null {
        const { server } = this.filter

        if (!server || server === params.parentId) {
            return this.fetchAux(params.id).then((status) => mapCybusNode(params, status))
        }

        return null
    }
}
