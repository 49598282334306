import { areCybusServersEquals, Capability, type CybusServer } from '../../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../../application'

import { type BackendDefinition, type BackendJsonResponseContent, FilteredAsyncMapper, mapCybusServer } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

class ServersMapper extends FilteredAsyncMapper<BackendDefinition<'protocol-mapper', 'Server'>, CybusServer> {
    protected override map (server: BackendDefinition<'protocol-mapper', 'Server'>): Promise<CybusServer> | null {
        const { service } = this.filter

        const cybusServer = mapCybusServer(server)

        if (service && service !== cybusServer.service) {
            return null
        }

        return this.fetchAux(cybusServer.id).then((status) => ({ ...cybusServer, status }))
    }
}

export class ServerStrategy extends BaseSubscriptionStrategy<'servers' | 'server'> {
    readonly requiredPermissions = [Capability.SERVERS_READ, Capability.SERVER_READ]

    protected override readonly supportedFilters: (keyof SubscriptionFilterArgs)[] = ['service']

    protected override readonly areEquals = areCybusServersEquals

    override async retrieveAll ({ service }: SubscriptionFilterArgs): Promise<CybusServer[]> {
        return this.retrieve({
            capability: Capability.SERVERS_READ,
            method: 'GET',
            path: '/api/servers',
            mapper: (data: BackendJsonResponseContent<'/api/servers', 'get', 200>) => {
                const mapper = new ServersMapper({ service }, (id) => this.rstAdapter.fetchStatusType('servers', id))

                for (const server of data) {
                    mapper.push(server)
                }

                return mapper.values
            },
        })
    }

    override retrieveOne (id: string): Promise<CybusServer> {
        return Promise.all([
            this.retrieve({
                capability: Capability.SERVER_READ,
                method: 'GET',
                path: '/api/servers/+',
                pathParams: [id],
                mapper: (data: BackendJsonResponseContent<'/api/servers/+', 'get', 200>) => data,
                handleNotFound: true,
            }),
            this.rstAdapter.fetchStatusType('server', id),
        ]).then(([server, status]) => ({ ...mapCybusServer(server), status }))
    }
}
