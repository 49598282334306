import type { PickByValueExact } from 'utility-types'

import { Capability, type StatusType } from '../../../../../../domain'
import type { SubscriptionsTypes } from '../../../../../../application'

import type { StatusSupportedType } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

abstract class StatusStrategy<K extends keyof PickByValueExact<SubscriptionsTypes, StatusType> & StatusSupportedType> extends BaseSubscriptionStrategy<K> {
    protected abstract readonly statusType: StatusSupportedType

    override retrieveOne (id: string): Promise<SubscriptionsTypes[K]> {
        return this.rstAdapter.fetchStatusType(this.statusType, id)
    }
}

export class MappingsStatusStrategy extends StatusStrategy<'mappingStatus'> {
    protected override statusType: StatusSupportedType = 'mappingStatus'

    readonly requiredPermissions = [Capability.MAPPING_STATE_READ]
}

export class NodesStatusStrategy extends StatusStrategy<'nodeStatus'> {
    protected override statusType: StatusSupportedType = 'nodeStatus'

    readonly requiredPermissions = [Capability.NODE_STATE_READ]
}

export class EndpointsStatusStrategy extends StatusStrategy<'endpointStatus'> {
    protected override statusType: StatusSupportedType = 'endpointStatus'

    readonly requiredPermissions = [Capability.ENDPOINT_STATE_READ]
}
