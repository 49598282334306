import type { ReadonlyRecord } from '../../../../../../utils'
import { areDetailedCybusMappingEquals, Capability, type CybusDetailedMapping } from '../../../../../../domain'

import { type BackendJsonResponseContent, mapDetailedMapping, type MappingProxyParams } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

export class DetailedMappingStrategy extends BaseSubscriptionStrategy<'mapping'> {
    readonly requiredPermissions = [Capability.MAPPING_READ]

    protected override readonly areEquals = areDetailedCybusMappingEquals

    override retrieveOne (id: string): Promise<CybusDetailedMapping> {
        return Promise.all([
            this.retrieve({
                capability: Capability.MAPPING_READ,
                method: 'GET',
                path: '/api/mappings/+',
                pathParams: [id],
                mapper: (data: MappingProxyParams) => data,
                handleNotFound: true,
            }),
            this.retrieve({
                capability: Capability.MAPPING_READ,
                method: 'GET',
                path: '/api/mappings/+/endpoint-topics',
                pathParams: [id],
                mapper: (data: BackendJsonResponseContent<'/api/mappings/+/endpoint-topics'>) => data,
                handleNotFound: true,
            }),
            this.rstAdapter.fetchStatusType('mapping', id),
        ]).then(([params, topics, status]) => ({ ...mapDetailedMapping(params, topics as typeof topics & ReadonlyRecord<string, string[]>), status }))
    }
}
