import { createArrayComparator, createEqualityChecker, type ReadonlyRecord } from '../../utils'

import type { CybusRole, CybusUser } from '.'

type FullCybusPermission<I = never> = Readonly<{
    id: I
    context: CybusPermissionContext
    resource: string
    read: boolean
    write: boolean
}>

export enum CybusPermissionContext {
    CONTROL_PLANE = 'CONTROL_PLANE',
    HTTP = 'HTTP',
    MQTT = 'MQTT',
}

/**
 * Contains the read write configuration of the permission
 */
export type CybusPermissionOperations = Pick<FullCybusPermission, 'read' | 'write'>

/**
 * Represents the permissions already persisted (wtih id) on the system
 */
export type CybusPersistedPermission = Pick<FullCybusPermission<string>, 'id' | 'context' | 'resource' | 'read' | 'write'>

/**
 * Created or uncreated permission, ready for manipulation
 */
export type EditableCybusPermission = Pick<FullCybusPermission<string | null>, 'id' | 'context' | 'resource' | 'read' | 'write'>

/**
 * Users are able to have permissions but also inherit them from roles
 * This object represents that inheritance
 */
export type EditableCybusPermissionInheritance = Pick<FullCybusPermission, 'context' | 'resource' | 'read' | 'write'> & Readonly<{ role: CybusRole['name'] }>

/**
 * A full editable permission merged with its inheritance
 */
export type EditableCybusPermissionWithInheritance = Readonly<{
    required: CybusPermissionOperations | null
    permission: EditableCybusPermission | null
    inheritanceRoles: CybusRole['name'][]
    inheritancesRead: boolean
    inheritancesWrite: boolean
}> &
    Pick<FullCybusPermission, 'context' | 'resource' | 'read' | 'write'>

/**
 * A standalone permission to be listed on its own on the application
 */
export type CybusPermission = Pick<FullCybusPermission, 'context' | 'resource'> &
    ReadonlyRecord<'roles' | 'users', ReadonlyRecord<CybusRole['name'] | CybusUser['username'], CybusPermissionOperations>>

export const isPermissionValid = (p: Pick<FullCybusPermission, 'read' | 'write' | 'resource'>): boolean => (p.write || p.read) && p.resource.length > 0

export const arePermissionArrayEquals = createArrayComparator<Pick<FullCybusPermission<unknown>, 'id' | 'context' | 'resource' | 'read' | 'write'>>(
    createEqualityChecker<Pick<FullCybusPermission<unknown>, 'id' | 'context' | 'resource' | 'read' | 'write'>>({
        context: null,
        id: null,
        resource: null,
        read: null,
        write: null,
    })
)
