import { isArrayNotEmpty } from '../../../../../../utils'
import type { SubscriptionsTypes } from '../../../../../../application'

import type { ServiceDeviationSupportedType } from '../../../../../Connectware'
import type { ServiceProxy } from '../../../../proxies'
import {
    createDeviationsEventsHandler,
    createProxyEventsHandler,
    SubscriptionHandlerType,
    type VrpcHandlerMappingPropertiesArgs,
    type VrpcInstanceToListSubscriptionHandler,
} from '..'
import { BaseServiceProxyHandler } from './Service'

type ServiceLinksHandler = VrpcInstanceToListSubscriptionHandler<ServiceProxy, SubscriptionsTypes['servicesLinks']>
type ServiceLinksHandlerArgs = VrpcHandlerMappingPropertiesArgs<ServiceLinksHandler>

export class VrpcLinkedServiceProxyInstanceHandler
    extends BaseServiceProxyHandler<SubscriptionHandlerType.INSTANCE_ONE_TO_LIST>
    implements ServiceLinksHandler {
    private readonly createStateListener = createProxyEventsHandler<ServiceProxy>('state')

    private readonly createDeviationsListener = createDeviationsEventsHandler(this.getDeviationSupportedType())

    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_LIST

    protected override getDeviationSupportedType (): ServiceDeviationSupportedType {
        return 'servicesLinks'
    }

    async onChange (args: ServiceLinksHandlerArgs['OnChangeArgs']): Promise<ServiceLinksHandlerArgs['OnChangeUnsub']> {
        const [unsubscribe, unsubscribeProxyEvent] = await Promise.all([this.createDeviationsListener(args), this.createStateListener(args)])

        return async (isGone: boolean): Promise<void> => {
            await Promise.all([unsubscribeProxyEvent(isGone), unsubscribe(isGone)])
        }
    }

    async mapToDomain (args: ServiceLinksHandlerArgs['DomainMapperArgs']): Promise<ServiceLinksHandlerArgs['Domain']> {
        const { id, name, links } = await this.mapToCybusService(args)

        if (isArrayNotEmpty(links)) {
            return [{ id, name, links }]
        }

        return []
    }
}
