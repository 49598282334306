import { areArrayEquals, isArrayNotEmpty, objectKeys } from '../../../../../utils'
import { type Capability, ConnectwareError, ConnectwareErrorType } from '../../../../../domain'
import type { SubscriptionFilterArgs, SubscriptionsTypes } from '../../../../../application'

import type { BackendPath, RSTAdapter } from '../../../../Connectware'
import type { HttpRequestArgs, HttpRequestBody } from '../../..'
import type { SubscriptionStrategy } from '..'

export type BaseStrategyRequestArgs<Path extends BackendPath, Body, Ouput> = Pick<
    HttpRequestArgs<Path, never, Body>,
    'method' | 'path' | 'pathParams' | 'capability'
> &
    Readonly<{ mapper: (r: Body) => Ouput, handleNotFound?: true }>

export abstract class BaseSubscriptionStrategy<T extends keyof SubscriptionsTypes> implements SubscriptionStrategy<T> {
    protected readonly supportedFilters: (keyof SubscriptionFilterArgs)[] | null = null

    abstract readonly requiredPermissions: Capability[]

    constructor (
        private readonly request: <Path extends BackendPath, ReqBody extends HttpRequestBody, Return>(
            args: HttpRequestArgs<Path, ReqBody, Return>
        ) => Promise<Return>,
        protected readonly rstAdapter: RSTAdapter
    ) {}

    // eslint-disable-next-line class-methods-use-this
    private compare<R> (oldResult: R | ConnectwareError, newResult: R | ConnectwareError, comparator: (oldResult: R, newResult: R) => boolean): boolean {
        return ConnectwareError.is(oldResult) || ConnectwareError.is(newResult) ? oldResult === newResult : comparator(oldResult, newResult)
    }

    // eslint-disable-next-line class-methods-use-this
    protected areEquals = (oldResult: SubscriptionsTypes[T], newResult: SubscriptionsTypes[T]): boolean => oldResult === newResult

    protected retrieve<Path extends BackendPath, ResponseBody, Output> ({
        handleNotFound,
        mapper,
        ...strategyArgs
    }: BaseStrategyRequestArgs<Path, ResponseBody, Output>): Promise<Output> {
        let args: HttpRequestArgs<Path, never, Output> = {
            authenticate: true,
            handlers: { 200: (response) => response.getJson<ResponseBody>().then(mapper) },
            ...strategyArgs,
        }

        if (handleNotFound) {
            args = {
                ...args,
                handlers: {
                    ...args.handlers,
                    404: (response) =>
                        response.getText().then(
                            (text) =>
                                new ConnectwareError(ConnectwareErrorType.NOT_FOUND, 'The endpoint yielded a 404 result', {
                                    path: strategyArgs.path,
                                    pathParams: strategyArgs.pathParams,
                                    text,
                                })
                        ),
                },
            }
        }

        return this.request(args)
    }

    supportsFilters (filter: SubscriptionFilterArgs): boolean {
        const { supportedFilters } = this
        const presentFilters = objectKeys(filter)

        return !isArrayNotEmpty(presentFilters) || Boolean(supportedFilters && presentFilters.every((f) => supportedFilters.includes(f)))
    }

    // eslint-disable-next-line class-methods-use-this
    retrieveAll (filter: SubscriptionFilterArgs): Promise<SubscriptionsTypes[T][]> {
        throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Method not implemented', { method: 'retrieveAll', filter })
    }

    // eslint-disable-next-line class-methods-use-this
    retrieveOne (id: string): Promise<SubscriptionsTypes[T]> {
        throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Method not implemented', { method: 'retrieveOne', id })
    }

    // eslint-disable-next-line class-methods-use-this
    retrieveCount (): Promise<number> {
        throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Method not implemented', { method: 'retrieveCount' })
    }

    compareAll (oldResults: SubscriptionsTypes[T][] | ConnectwareError, newResults: SubscriptionsTypes[T][] | ConnectwareError): boolean {
        return this.compare(oldResults, newResults, (oldResults, newResults) =>
            areArrayEquals(oldResults, newResults, { sort: false, equals: (a, b) => this.compareOne(a, b) })
        )
    }

    compareOne (oldResult: SubscriptionsTypes[T] | ConnectwareError, newResult: SubscriptionsTypes[T] | ConnectwareError): boolean {
        return this.compare(oldResult, newResult, (oldResult, newResult) => this.areEquals(oldResult, newResult))
    }
}
