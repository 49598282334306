import { areCybusEndpointEquals, Capability, type CybusEndpoint } from '../../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../../application'

import { CybusEndpointMapper, type EndpointProxyParams } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

export class EndpointsStrategy extends BaseSubscriptionStrategy<'endpoints'> {
    readonly requiredPermissions = [Capability.ENDPOINTS_READ]

    protected override readonly supportedFilters: (keyof SubscriptionFilterArgs)[] = ['service', 'connection']

    protected override readonly areEquals = areCybusEndpointEquals

    override async retrieveAll (filter: SubscriptionFilterArgs): Promise<CybusEndpoint[]> {
        return this.retrieve({
            capability: Capability.ENDPOINTS_READ,
            method: 'GET',
            path: '/api/endpoints',
            mapper: (data: EndpointProxyParams[]) => {
                const mapper = new CybusEndpointMapper(filter, (id) => this.rstAdapter.fetchStatusType('endpoints', id))

                data.forEach((param) => mapper.push(param))

                return mapper.values
            },
        })
    }
}
