import type { CybusService } from '../../../../../../domain'
import type { SubscriptionsTypes } from '../../../../../../application'

import { mapService, SERVICE_AGENT, type ServiceDeviationSupportedType } from '../../../../../Connectware'
import { SERVICE_CLASSNAME } from '../../../../constants'
import type { ServiceProxy } from '../../../../proxies'
import {
    createDeviationsEventsHandler,
    createProxyEventsHandler,
    SubscriptionHandlerType,
    type VrpcHandlerMappingPropertiesArgs,
    type VrpcInstanceToOneSubscriptionHandler,
} from '..'

type ServiceHandler = VrpcInstanceToOneSubscriptionHandler<ServiceProxy, SubscriptionsTypes['services']>
type HandlerPropertiesArgs = VrpcHandlerMappingPropertiesArgs<ServiceHandler>
type DomainMappingArgs = Pick<HandlerPropertiesArgs['DomainMapperArgs'], 'instance' | 'rstAdapter'>

export abstract class BaseServiceProxyHandler<T extends SubscriptionHandlerType> {
    abstract readonly type: T

    readonly optionalFilters = []

    readonly requiredFilters = []

    readonly classNameFilter = SERVICE_CLASSNAME

    readonly agent = SERVICE_AGENT

    readonly ignoreInstances = null

    readonly ignoreInstanceByFilter = null

    readonly sourceInstanceName = null

    protected abstract getDeviationSupportedType (): ServiceDeviationSupportedType

    protected async mapToCybusService ({ instance, rstAdapter }: DomainMappingArgs): Promise<CybusService> {
        return Promise.all([instance.getInfo(), instance.getLinks(), rstAdapter.fetchIsDeviated(this.getDeviationSupportedType(), instance._targetId)]).then(
            ([info, links, isDeviated]) => mapService(info, isDeviated, links)
        )
    }
}

export class VrpcServiceProxyInstanceHandler extends BaseServiceProxyHandler<SubscriptionHandlerType.INSTANCE_ONE_TO_ONE> implements ServiceHandler {
    private readonly createStateListener = createProxyEventsHandler<ServiceProxy>('state')

    private readonly createDeviationsListener = createDeviationsEventsHandler(this.getDeviationSupportedType())

    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_ONE

    protected override getDeviationSupportedType (): ServiceDeviationSupportedType {
        return 'services'
    }

    async onChange (args: HandlerPropertiesArgs['OnChangeArgs']): Promise<HandlerPropertiesArgs['OnChangeUnsub']> {
        const [unsubscribe, unsubscribeProxyEvent] = await Promise.all([this.createDeviationsListener(args), this.createStateListener(args)])

        return async (isGone: boolean): Promise<void> => {
            await Promise.all([unsubscribeProxyEvent(isGone), unsubscribe(isGone)])
        }
    }

    mapToDomain (args: DomainMappingArgs): Promise<CybusService> {
        return this.mapToCybusService(args)
    }
}
