import type { ReadonlyRecord } from '../../../../../../utils'
import { areCybusServiceEquals, Capability, type CybusService } from '../../../../../../domain'

import { type BackendJsonResponseContent, type CybusServiceProxyInfo, type LinkedService, mapBaseService } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

export class ServicesStrategy extends BaseSubscriptionStrategy<'services'> {
    readonly requiredPermissions = [Capability.SERVICES_READ]

    protected override readonly areEquals = areCybusServiceEquals

    override retrieveAll (): Promise<CybusService[]> {
        return Promise.all([
            this.retrieve({
                capability: Capability.SERVICES_READ,
                method: 'GET',
                path: '/api/services/info',
                mapper: (data: CybusServiceProxyInfo[]) => data,
            }),
            this.retrieve({
                capability: Capability.SERVICES_READ,
                method: 'GET',
                path: '/api/services/all-links',
                mapper: (data: LinkedService[]) => data,
            }),
        ]).then(async ([info, links]) => {
            const indexed = links.reduce<ReadonlyRecord<LinkedService['id'], LinkedService['links']>>(
                (r, linkedService) => ({ ...r, [linkedService.id]: linkedService.links }),
                {}
            )

            const results = await Promise.all(
                info.map(async (data) => {
                    const isDeviated = await this.rstAdapter.fetchIsDeviated('services', data.id)
                    return { ...mapBaseService(data, isDeviated), links: indexed[data.id] ?? [] }
                })
            )
            return results.sort((a, b) => a.id.localeCompare(b.id))
        })
    }

    override retrieveCount (): Promise<number> {
        return this.retrieve({
            capability: Capability.SERVICES_READ,
            method: 'GET',
            path: '/api/services/instances',
            mapper: (data: BackendJsonResponseContent<'/api/services/instances', 'get', 200>) => data.length,
        })
    }
}
