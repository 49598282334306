import type { PickByValue } from 'utility-types'
import React, { type FC, type PropsWithChildren, useCallback, useEffect, useMemo } from 'react'

import type { NonEmptyArray, ReadonlyRecord } from '../../../../utils'
import { type AppState, arePagesEquals, ConnectwareError, type Page, type SortableColumn } from '../../../../domain'
import type { PageSubscriptionArgs, SubscriptionFilterArgs, SubscriptionUpdateParameters, Usecases } from '../../../../application'

import { type AbsoluteRoutePathWithId, type RelativeRoutePathWithId, useRouteWithIdRedirect } from '../../routing'
import { useAppState, useAppUsecase } from '../../State'
import { ErrorMessage } from '../../ErrorMessage'
import { CircularLoader, Table, type TableColumn, type TableCustomizedState, type TableLine, type TablePagination, type TableProps } from '../../common'

import { useSelection, type UseSelectionProps } from './Selection'

/** The columns can only have their display changed */
type DisplayChangingCoercion<TableResource,> = Partial<
    Readonly<{ [P in keyof TableResource]: Pick<TableColumn<TableResource[P], TableResource>, 'customCellRender' | 'label'> }>
>

/** This type forces the sorting to now be a boolean, and only if the original resource also contains that column */
type SortableCoercion<DataResource,> = Partial<Readonly<{ [P in SortableColumn<DataResource>]: Readonly<{ sort?: true }> }>>

export type Columns<DataResource, TableResource,> = DisplayChangingCoercion<TableResource> & SortableCoercion<DataResource>

type ManagementUsecase<DataResource,> = Readonly<{
    /** Used to subscribe to changes */
    subscribe(args: PageSubscriptionArgs): VoidFunction
    /** Used to update the pagination parameters */
    updateParameters(update: SubscriptionUpdateParameters<DataResource>): void
    getPaginationSizes(): NonEmptyArray<number>
}>

type ResourceManagementUsecaseNames = keyof PickByValue<Usecases, ManagementUsecase<any>>

type ManagementUsecaseResource<U extends ManagementUsecase<any>,> = U extends ManagementUsecase<infer R> ? R : never
type ManagementUsecasesResource<U extends ResourceManagementUsecaseNames,> = ManagementUsecaseResource<Usecases[U]>

type Props<
    SubscriptionUsecaseName extends ResourceManagementUsecaseNames,
    DataResource extends ManagementUsecasesResource<SubscriptionUsecaseName>,
    TableResource extends TableLine,
    ManagementUsecases extends keyof Usecases,
> = Readonly<{
    /** The usecase the table will use to fetch data */
    subscriptionUsecase: SubscriptionUsecaseName

    /** Filter parameters for the resources to be fetched */
    filter?: SubscriptionFilterArgs

    /** How data should be provided for the table to be rendered */
    data: (s: AppState) => Page<DataResource>

    /**
     * Once errors/loading states have been filtered out
     * This function will be called to assist to creation of the contents of the table itself
     */
    dataTableMapper: (resource: DataResource[]) => TableResource[]

    /** The columns of the table */
    columns: Columns<DataResource, TableResource>

    /** If the list should be short */
    short?: boolean
}> &
    Pick<TableProps<TableResource>, 'onRowClick' | 'translations'> &
    UseSelectionProps<TableResource, ManagementUsecases>

/**
 * This component has a bult-in structure to render a table that
 *
 * - While rendered, subscribes to the given usecase (if the same is subscribable)
 * - Handles state changes (loading, error, success yielf) of the given resources in order to properly render the contents
 * - Has a standardized look and feel
 *
 * Children will be rendered with-in the Table component
 */
export const ResourcesTable = <
    SubscriptionUsecaseName extends ResourceManagementUsecaseNames,
    DataResource extends ManagementUsecasesResource<SubscriptionUsecaseName>,
    TableResource extends TableLine = DataResource & TableLine,
    ManagementUsecases extends keyof Usecases = never,
>({
    subscriptionUsecase: subscriptionUsecaseName,
    filter = {},
    data: dataSelector,
    hasManagementCapabilitiesSelector,
    dataTableMapper,
    translations,
    selection,
    short = false,
    children,
    ...props
}: PropsWithChildren<Props<ResourceManagementUsecaseNames, DataResource, TableResource, ManagementUsecases>>): ReturnType<FC> => {
    const subscriptionUsecase = useAppUsecase(subscriptionUsecaseName) as ManagementUsecase<DataResource>

    const resourcesPage = useAppState<Page<DataResource>>(dataSelector, arePagesEquals)
    const selectionConfig = useSelection({ hasManagementCapabilitiesSelector, selection })

    /** Subscribe to changes of resources */
    useEffect(
        () => subscriptionUsecase.subscribe({ ...filter, short, throttle: 200 }),
        [subscriptionUsecase, filter.connection, filter.server, filter.service, short]
    )

    /** Load the possible page sizes to be used */
    const pageSizeOptions = useMemo(() => subscriptionUsecase.getPaginationSizes(), [])

    const onCustomized = useCallback(
        (s: TableCustomizedState<TableResource>) => {
            const update: SubscriptionUpdateParameters<DataResource> =
                'search' in s || 'page' in s || 'pageSize' in s
                    ? s
                    : {
                          /**
                           * This coercion is fine, as the type SortableCoercion above is only allowing sorting for column in data
                           * @see {SortableCoercion}
                           */
                          sort: { column: s.sortOrder.name as SortableColumn<DataResource> & typeof s.sortOrder.name, asc: s.sortOrder.direction === 'asc' },
                      }
            subscriptionUsecase.updateParameters(update)
        },
        [subscriptionUsecase]
    )

    if (resourcesPage === null) {
        /* Resources have not loaded */
        return <CircularLoader data-testid="no-data-loader" />
    }

    if (ConnectwareError.is(resourcesPage.data)) {
        return <ErrorMessage data-testid="general-error-message" error={resourcesPage.data} stack extras="section" />
    }

    const pagination: TablePagination | undefined = resourcesPage.data
        ? { ...resourcesPage.pagination, pageSizeOptions, totalCount: resourcesPage.data.totalCount, showTotalCount: true }
        : undefined

    return (
        <Table
            data-testid={subscriptionUsecaseName}
            selection={selectionConfig}
            search={resourcesPage.search === null || resourcesPage.search}
            data={resourcesPage.data === null ? [] : dataTableMapper(resourcesPage.data.current)}
            loading={resourcesPage.data === null}
            pagination={pagination}
            sortOrder={{ name: resourcesPage.sort.column, direction: resourcesPage.sort.asc ? 'asc' : 'desc' }}
            translations={translations}
            onCustomized={onCustomized}
            {...props}
        >
            {children}
        </Table>
    )
}

type RedirectingProps<
    SubscriptionUsecaseName extends ResourceManagementUsecaseNames,
    DataResource extends ManagementUsecasesResource<SubscriptionUsecaseName>,
    TableResource extends TableLine,
    ManagementUsecases extends keyof Usecases,
> = Omit<Props<ResourceManagementUsecaseNames, DataResource, TableResource, ManagementUsecases>, 'onRowClick'> &
    Readonly<{ redirectOnRowclick: AbsoluteRoutePathWithId | RelativeRoutePathWithId }>

/**
 * The base resource needs an id for redirecting else where
 */
type RedirectingResource = TableLine & ReadonlyRecord<'id', string>

export const RedirectingResourcesTable = <
    SubscriptionUsecaseName extends ResourceManagementUsecaseNames,
    DataResource extends ManagementUsecasesResource<SubscriptionUsecaseName> & RedirectingResource = ManagementUsecasesResource<SubscriptionUsecaseName> &
        RedirectingResource,
    TableResource extends RedirectingResource = DataResource,
    ManagementUsecases extends keyof Usecases = never,
>({
    redirectOnRowclick,
    ...props
}: PropsWithChildren<RedirectingProps<ResourceManagementUsecaseNames, DataResource, TableResource, ManagementUsecases>>): ReturnType<FC> => {
    const onRowClick = useRouteWithIdRedirect<TableResource>(redirectOnRowclick, ({ id }) => id)
    return onRowClick ? <ResourcesTable {...props} onRowClick={onRowClick} /> : <ResourcesTable {...props} />
}
