import type { CybusMapping, StatusType } from '../../../../../../domain'

import { mapMapping, type MappingProxyParams, type StatusSupportedType } from '../../../../../Connectware'

import { createStatusEventsHandler, excludeInstanceByServiceName } from '..'
import { VrpcMappingToOneSubscriptionHandler } from './Base'

export class VrpcMappingProxyInstanceHandler extends VrpcMappingToOneSubscriptionHandler<'mappings', void> {
    readonly optionalFilters = ['service' as const]

    readonly ignoreInstanceByFilter = excludeInstanceByServiceName

    readonly onChange = createStatusEventsHandler(this.getStatusSupportedType())

    protected readonly statusSupportedType: StatusSupportedType = 'mappings'

    protected override getStatusSupportedType (): StatusSupportedType {
        return 'mappings'
    }

    protected override mapMapping (params: MappingProxyParams, status: StatusType): CybusMapping {
        return { ...mapMapping(params), status }
    }

    protected fetchInformation (): Promise<void> {
        return Promise.resolve()
    }
}
