import type { ContainerInfo, ContainerInspectInfo } from 'dockerode'
import type { V1ObjectMeta, V1PodStatus, V1Volume } from '@kubernetes/client-node'

import type { ReadonlyRecord } from '../../../utils'

import type { BackendJsonRequestContent } from '.'

/**
 * @todo generate this type from BE images
 * @see https://cybusio.atlassian.net/browse/CC-2690
 * @see https://bitbucket.org/cybusio/cybus/src/3b095cf5b98981d4c99eea420d7de478dff0b82c/auth-server/src/swagger/swagger.yaml#lines-1679
 */
export type PermissionContext = 'control_plane' | 'http' | 'mqtt'

/**
 * @todo generate this type from BE images
 * @see https://cybusio.atlassian.net/browse/CC-2690
 * @see https://bitbucket.org/cybusio/cybus/src/d8576f7bfe9245b76edce87ee4f2248033ea8455/protocol-mapper/src/swagger/swagger.yaml#protocol-mapper/src/swagger/swagger.yaml-304
 */
export type AvailableTopicsResponse = ReadonlyRecord<'endpoints' | 'mappings' | 'nodes', ReadonlyRecord<string, string[]>>

/**
 * @todo generate this type from BE images
 * @see https://cybusio.atlassian.net/browse/CC-2690
 * @see https://bitbucket.org/cybusio/cybus/src/f8567e690904e871dbb722a3b54efcf78419b136/service-manager/src/swagger/swagger.yaml#service-manager/src/swagger/swagger.yaml-373
 */
export type ServiceOperationRequest = Readonly<{ operation: 'disable' | 'enable' }> & BackendJsonRequestContent<'/api/services/+/operation', 'put'>

/**
 * @todo generate this type from BE images
 * @see https://cybusio.atlassian.net/browse/CC-2690
 * @see https://bitbucket.org/cybusio/cybus/src/c5ad95fbf0f22f04d5d6eaed17bcd5f765e65601/container-manager/src/swagger/swagger.yaml#lines-205
 */
export type OrchestratorResponse = Readonly<{ orchestrator: 'Docker' | 'Kubernetes' }>

/**
 * @todo generate this type from BE images
 * @see https://cybusio.atlassian.net/browse/CC-2690
 * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/container-manager/src/Docker.js#lines-71
 * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/container-manager/src/swagger/swagger.yaml#lines-28
 */
export type DockerContainersResponse = Pick<ContainerInfo, 'Names' | 'State' | 'Mounts'>[]

/**
 * @todo generate this type from BE images
 * @see https://cybusio.atlassian.net/browse/CC-2690
 * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/container-manager/src/Kubernetes.js#lines-46
 * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/container-manager/src/swagger/swagger.yaml#lines-28
 */
export type KubernetesContainersResponse = {
    Id: V1ObjectMeta['uid']
    Names: [V1ObjectMeta['name']]
    Image: '?'
    ImageID: '?'
    Command: '?'
    State?: Uppercase<Exclude<V1PodStatus['phase'], undefined>>
    Mounts: {
        Type: 'volume'
        Name: V1Volume['name']
        Source: '?'
        Destination: '?'
        Mode: '?'
        RW: '?'
        Propagation: '?'
        Driver?: 'persistentVolumeClaim' | 'secret'
    }[]
}[]

/**
 * @todo generate this type from BE images
 * @see https://cybusio.atlassian.net/browse/CC-2690
 * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/container-manager/src/Docker.js#lines-202
 * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/container-manager/src/swagger/swagger.yaml#lines-32
 */
export type DockerContainerResponse = Pick<ContainerInspectInfo, 'Created' | 'Mounts' | 'Config' | 'HostConfig' | 'Id' | 'Name' | 'State'>

/**
 * @todo generate this type from BE images
 * @see https://cybusio.atlassian.net/browse/CC-2690
 * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/container-manager/src/Kubernetes.js#lines-158
 */
export type KubernetesContainerResponse = Readonly<{
    Id: V1ObjectMeta['uid']
    Name: `/${V1ObjectMeta['name']}`
    /**
     * @see  V1PodStatus['startTime']
     */
    Created: string | undefined
    Mounts: Readonly<{
        Type: 'volume'
        Name: V1Volume['name']
        Source: '?'
        Destination: '?'
        Mode: '?'
        RW: '?'
        Propagation: '?'
        Driver?: 'persistentVolumeClaim' | 'secret'
    }>[]
    Config: Readonly<{ Image: string, WorkingDir: string, Labels: ReadonlyRecord<string, never>, ExposedPorts: ReadonlyRecord<string, never>, Env: string[] }>
    State: Readonly<{ Status: Uppercase<Exclude<V1PodStatus['phase'], undefined>> | Exclude<V1PodStatus['phase'], string> }>
    HostConfig: Readonly<{ CapAdd: null, Privileged: false }>
}>

/**
 * @todo generate this type from BE images
 * @see https://cybusio.atlassian.net/browse/CC-2690
 * @see https://bitbucket.org/cybusio/cybus/src/b58bcc30fb609b40c6dd55df6b3ba9e5a6e2b712/service-manager/src/swagger/swagger.yaml#service-manager/src/swagger/swagger.yaml-477
 */
export type LinkedService = Readonly<{ id: string, name: string, links: Readonly<{ name: string, href: string }>[] }>
