import type { StatusType } from '../../../domain'
import type { SubscriptionFilterArgs } from '../../../application'

/**
 * Helper class to address the need to fetch async information
 * in order to map domain entities
 */
export abstract class AsyncMapper<Resource, Aux, Domain> {
    private readonly promises: Promise<Domain | null>[] = []

    constructor (protected readonly fetchAux: (resourceId: string) => Promise<Aux>) {}

    get values (): Promise<Domain[]> {
        return Promise.all(this.promises).then((results: (Domain | null)[]) => results.filter((r): r is Domain => r !== null))
    }

    protected abstract map (resource: Resource): Promise<Domain | null> | null

    push (resource: Resource): void {
        this.promises.push(Promise.resolve(this.map(resource)))
    }
}

/**
 * Simple extension to add filter support
 */
export abstract class FilteredAsyncMapper<Resource, Domain> extends AsyncMapper<Resource, StatusType, Domain> {
    constructor (protected readonly filter: SubscriptionFilterArgs, ...args: ConstructorParameters<typeof AsyncMapper<Resource, StatusType, Domain>>) {
        super(...args)
    }
}
