import { ConnectwareError, ConnectwareErrorType } from '../../../../../domain'
import type { SubscriptionFilterArgs, SubscriptionsTypes } from '../../../../../application'

import type { RSTAdapter } from '../../../../Connectware'
import type { SubscriptionHandlerType, VrpcInstanceTypes, VrpcSubscriptionHandler } from '../handlers'
import type { DataMapper } from '../mappers'

export abstract class BaseSubscription<T extends keyof SubscriptionsTypes, Data> {
    private internalFilter: SubscriptionFilterArgs = {}

    protected static isHandlerOfType<Type extends SubscriptionHandlerType, VrpcInstance, Domain> (
        handler: Pick<VrpcSubscriptionHandler<VrpcInstance, Domain>, 'type'>,
        ...types: Type[]
    ): handler is VrpcSubscriptionHandler<VrpcInstance, Domain, Type> {
        return types.includes(handler.type as Type)
    }

    constructor (protected readonly handler: VrpcSubscriptionHandler<VrpcInstanceTypes[T], SubscriptionsTypes[T]>, protected readonly rstAdapter: RSTAdapter) {}

    protected get filter (): SubscriptionFilterArgs {
        return this.internalFilter
    }

    protected abstract createMapper (): DataMapper<Data> | null

    withFilter (filter: SubscriptionFilterArgs): this {
        this.internalFilter = filter
        return this
    }

    build (): DataMapper<Data> {
        const mapper = this.createMapper()

        if (mapper) {
            return mapper
        }

        throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'No mapper was found to be used')
    }
}
