import type { CybusDetailedEndpoint } from '../../../../../../domain'

import { mapDetailedEndpoint } from '../../../../../Connectware'

import { ENDPOINT_CLASSNAME_FILTER } from '../../../../constants'
import type { EndpointProxy } from '../../../../proxies'
import { createStatusEventsHandler, SubscriptionHandlerType, type VrpcHandlerMappingPropertiesArgs, type VrpcInstanceToOneSubscriptionHandler } from '..'

type CybusDetailedEndpointHandler = VrpcInstanceToOneSubscriptionHandler<EndpointProxy, CybusDetailedEndpoint>
type CybusDetailedEndpointHandlerArgs = VrpcHandlerMappingPropertiesArgs<CybusDetailedEndpointHandler>

export class VrpcDetailedCEndpointProxyInstanceHandler implements CybusDetailedEndpointHandler {
    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_ONE

    readonly optionalFilters = []

    readonly requiredFilters = []

    readonly classNameFilter = ENDPOINT_CLASSNAME_FILTER

    readonly ignoreInstances = null

    readonly sourceInstanceName = null

    readonly ignoreInstanceByFilter = null

    readonly agent = null

    readonly onChange = createStatusEventsHandler('endpoint')

    mapToDomain ({ instance, rstAdapter }: CybusDetailedEndpointHandlerArgs['DomainMapperArgs']): Promise<CybusDetailedEndpointHandlerArgs['Domain']> {
        return Promise.all([instance.getParams(), instance.getTopics(), rstAdapter.fetchStatusType('endpoint', instance._targetId)]).then(
            ([params, topics, status]) => ({ ...mapDetailedEndpoint(params, topics), status })
        )
    }
}
