import type { Intersection, ValuesType } from 'utility-types'

import { objectEntries } from '../../../utils'
import { ConnectwareError, ConnectwareErrorType, type CybusDetailedEndpoint, type CybusEndpoint, type CybusEndpointAddress } from '../../../domain'

import type { EndpointProxyParams } from '../proxies'
import { mapResourceNames } from './Resource'
import { FilteredAsyncMapper } from './Async'

const isExpectedAddressValueType = (value: ValuesType<EndpointProxyParams['address']>): value is ValuesType<CybusEndpointAddress> =>
    Boolean(
        typeof value === 'number' ||
            typeof value === 'boolean' ||
            typeof value === 'string' ||
            (value && typeof value === 'object' && Object.values(value).every((v) => typeof v === 'string'))
    )

const isExpectedAddressType = (address: EndpointProxyParams['address']): address is CybusEndpointAddress =>
    typeof address === 'object' && objectEntries(address).every(([key, value]) => typeof key === 'string' && isExpectedAddressValueType(value))

const mapToAddress = (address: EndpointProxyParams['address']): CybusEndpointAddress => {
    if (!isExpectedAddressType(address)) {
        throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Unexpected address type', address)
    }

    return address
}

const mapBaseEndpoint = ({
    id,
    operation,
    protocol,
    agentName,
}: Pick<EndpointProxyParams, 'id' | 'operation' | 'protocol' | 'agentName'>): Omit<Intersection<CybusDetailedEndpoint, CybusEndpoint>, 'status'> => {
    const [service, name] = mapResourceNames(id)
    return { id, name, service, operation, protocol, agent: agentName || null }
}

export class CybusEndpointMapper extends FilteredAsyncMapper<EndpointProxyParams, CybusEndpoint> {
    protected map ({ connectionId, ...params }: EndpointProxyParams): Promise<CybusEndpoint> | null {
        const { service, connection } = this.filter

        if (connection && connection !== connectionId) {
            return null
        }

        const endpoint = { ...mapBaseEndpoint(params), connection: connectionId || null }
        if (service && service !== endpoint.service) {
            return null
        }

        return this.fetchAux(endpoint.id).then((status) => ({ status, ...endpoint }))
    }
}

export const mapDetailedEndpoint = (
    { address, rules, ...params }: EndpointProxyParams,
    topics: CybusDetailedEndpoint['topics']
): Omit<CybusDetailedEndpoint, 'status'> => ({
    ...mapBaseEndpoint(params),
    address: mapToAddress(address),
    rules: rules || null,
    topics,
})
