import type { ValuesType } from 'utility-types'
import { Fragment, useCallback } from 'react'
import { DataUsage, Tag } from '@mui/icons-material'

import { CybusPermissionContext, Translation } from '../../../../domain'
import { useTranslator } from '../../Internationalization'

const contextValues = {
    /**
     * It is of the interest of backend to not have the control plane permissions be editable right now
     * Once and if that changes, this config object ought to be revised
     */
    [CybusPermissionContext.CONTROL_PLANE]: { name: 'Control Plane', label: Translation.SUBJECT, icon: Fragment, editable: false },
    [CybusPermissionContext.HTTP]: { name: 'Http', label: Translation.ENDPOINT, icon: DataUsage, editable: true },
    [CybusPermissionContext.MQTT]: { name: 'Mqtt', label: Translation.TOPIC, icon: Tag, editable: true },
} as const

type ContextValue = ValuesType<typeof contextValues>

const getContextValue = <Prop extends keyof ContextValue>(prop: Prop, context: CybusPermissionContext): ContextValue[Prop] => contextValues[context][prop]

export const useContextName = (): ((context: CybusPermissionContext) => ContextValue['name']) => (context) => getContextValue('name', context)

export const useContextIcon = (context: CybusPermissionContext): ContextValue['icon'] => getContextValue('icon', context)

export const useIsContextEditable = (): ((context: CybusPermissionContext) => ContextValue['editable']) => (context) => getContextValue('editable', context)

export const useContextLabel = (): ((context: CybusPermissionContext, count?: number) => string) => {
    const translator = useTranslator()
    return useCallback((context, count = 1) => translator.formatTranslation(getContextValue('label', context), { count }), [translator])
}
