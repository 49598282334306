import { areCybusDetailedConnectionEquals, Capability, type CybusDetailedConnection } from '../../../../../../domain'
import { type ConnectionProxyParams, mapDetailedConnection } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

export class DetailedConnectionStrategy extends BaseSubscriptionStrategy<'connection'> {
    readonly requiredPermissions = [Capability.CONNECTION_READ]

    protected override readonly areEquals = areCybusDetailedConnectionEquals

    override retrieveOne (id: string): Promise<CybusDetailedConnection> {
        return Promise.all([
            this.retrieve({
                capability: Capability.CONNECTION_READ,
                method: 'GET',
                path: '/api/connections/+',
                pathParams: [id],
                mapper: (data: ConnectionProxyParams) => mapDetailedConnection(data),
                handleNotFound: true,
            }),
            this.rstAdapter.fetchStatusType('connection', id),
        ]).then(([param, status]) => ({ ...param, status }))
    }
}
