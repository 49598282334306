import type { ReadonlyRecord } from '../../../utils'
import { Capability, ConnectwareError, ConnectwareErrorType, StatusType } from '../../../domain'

import {
    type BackendPath,
    mapRSTResourceStatusViewCurrentStatusType,
    mapRSTResourceStatusViewId,
    type StatusSupportedType,
    type StatusTypeFetcher,
} from '../../Connectware'
import { RSTBatchedConnectwareHTTPService, type RSTBatchFetchingStrategy } from './Base'

type StatusFetchingArgs = Readonly<{ type: StatusSupportedType, resourceId: string }>
type StatusBatch = ReadonlyRecord<string, StatusType>

type SupportedRSTPaths = Extract<BackendPath, '/api/v2/resources/states' | '/api/v2/resource-runners'>

export class RSTStatusTypeHTTPService
    extends RSTBatchedConnectwareHTTPService<StatusFetchingArgs, SupportedRSTPaths, StatusBatch, StatusType>
    implements StatusTypeFetcher {
    private readonly resourceStrategiesInteraction: Omit<
        RSTBatchFetchingStrategy<StatusFetchingArgs, '/api/v2/resources/states', StatusBatch>,
        'path' | 'method' | 'capability'
    > = {
        buildQueryParams: (requests) => ({ itemsPerPage: `${this.pageSize}`, resourceIds: requests.map(({ resourceId }) => resourceId) }),
        status: 200,
        mapJsonResponse: ({ data }) =>
            data.reduce<StatusBatch>(
                (r, view) => ({ ...r, [mapRSTResourceStatusViewId(view.resource)]: mapRSTResourceStatusViewCurrentStatusType(view.state) }),
                {}
            ),
    }

    /**
     * These strategies here serve two purposes
     * - Allow for the extraction of statuses to be possible by many means
     * - Enables the scripts that check for endpoint usage (and associate them with capabilities) to detect these endpoints' usage properly
     */
    private readonly statesStrategies: ReadonlyRecord<
        StatusFetchingArgs['type'],
        | RSTBatchFetchingStrategy<StatusFetchingArgs, '/api/v2/resources/states', StatusBatch>
        | RSTBatchFetchingStrategy<StatusFetchingArgs, '/api/v2/resource-runners', StatusBatch>
    > = {
        agents: {
            method: 'GET',
            path: '/api/v2/resource-runners',
            capability: Capability.AGENTS_READ,
            buildQueryParams: (requests) => ({ itemsPerPage: `${this.pageSize}`, names: requests.map(({ resourceId }) => resourceId) }),
            status: 200,
            mapJsonResponse: ({ data }) =>
                /**
                 * Maps out the status of a runner, a healthy runner is always online, otherwise offline
                 */
                data.reduce<StatusBatch>(
                    (r, { name: id, healthy }) => ({ ...r, [mapRSTResourceStatusViewId({ id })]: healthy ? StatusType.ONLINE : StatusType.OFFLINE }),
                    {}
                ),
        },
        connection: { method: 'GET', path: '/api/v2/resources/states', capability: Capability.CONNECTION_READ, ...this.resourceStrategiesInteraction },
        connections: { method: 'GET', path: '/api/v2/resources/states', capability: Capability.CONNECTIONS_READ, ...this.resourceStrategiesInteraction },
        endpoint: { method: 'GET', path: '/api/v2/resources/states', capability: Capability.ENDPOINT_READ, ...this.resourceStrategiesInteraction },
        endpoints: { method: 'GET', path: '/api/v2/resources/states', capability: Capability.ENDPOINTS_READ, ...this.resourceStrategiesInteraction },
        endpointStatus: { method: 'GET', path: '/api/v2/resources/states', capability: Capability.ENDPOINT_STATE_READ, ...this.resourceStrategiesInteraction },
        mapping: { method: 'GET', path: '/api/v2/resources/states', capability: Capability.MAPPING_READ, ...this.resourceStrategiesInteraction },
        mappings: { method: 'GET', path: '/api/v2/resources/states', capability: Capability.MAPPINGS_READ, ...this.resourceStrategiesInteraction },
        mappingStatus: { method: 'GET', path: '/api/v2/resources/states', capability: Capability.MAPPING_STATE_READ, ...this.resourceStrategiesInteraction },
        nodes: { method: 'GET', path: '/api/v2/resources/states', capability: Capability.NODES_READ, ...this.resourceStrategiesInteraction },
        nodeStatus: { method: 'GET', path: '/api/v2/resources/states', capability: Capability.NODE_STATE_READ, ...this.resourceStrategiesInteraction },
        server: { method: 'GET', path: '/api/v2/resources/states', capability: Capability.SERVER_READ, ...this.resourceStrategiesInteraction },
        servers: { method: 'GET', path: '/api/v2/resources/states', capability: Capability.SERVERS_READ, ...this.resourceStrategiesInteraction },
    }

    protected resolveBatchFetchingStrategy (args: StatusFetchingArgs): RSTBatchFetchingStrategy<StatusFetchingArgs, SupportedRSTPaths, StatusBatch> {
        return this.statesStrategies[args.type] as RSTBatchFetchingStrategy<StatusFetchingArgs, SupportedRSTPaths, StatusBatch>
    }

    // eslint-disable-next-line class-methods-use-this
    protected resolveBatchResponse (result: StatusBatch, { resourceId }: StatusFetchingArgs): StatusType | ConnectwareError {
        return result[resourceId] ?? new ConnectwareError(ConnectwareErrorType.NOT_FOUND, 'Could not find status in batch', { resourceId })
    }

    fetchStatusType (type: StatusSupportedType, resourceId: string): Promise<StatusType> {
        return this.fetchBatched({ type, resourceId })
    }
}
