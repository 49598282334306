import type { ReadonlyRecord } from '../../../../../../utils'
import { ConnectwareError, ConnectwareErrorType, type CybusDetailedMapping, type StatusType } from '../../../../../../domain'
import type { SubscriptionsTypes } from '../../../../../../application'

import { mapDetailedMapping, type MappingProxyParams, type StatusSupportedType } from '../../../../../Connectware'
import type { MappingProxy } from '../../../../proxies'

import { createProxyEventsHandler, createStatusEventsHandler, type VrpcHandlerMappingPropertiesArgs, type VrpcInstanceToOneSubscriptionHandler } from '..'
import { VrpcMappingToOneSubscriptionHandler } from './Base'

type MappingHandler = VrpcInstanceToOneSubscriptionHandler<MappingProxy, SubscriptionsTypes['mapping']>
type MappingHandlerArgs = VrpcHandlerMappingPropertiesArgs<MappingHandler>

export class VrpcDetailedMappingProxyInstanceHandler extends VrpcMappingToOneSubscriptionHandler<'mapping', ReadonlyRecord<string, string[]>> {
    private readonly createExecutionErrorListener = createProxyEventsHandler<MappingProxy>('executionError')

    private readonly createStatusChangeListener = createStatusEventsHandler(this.getStatusSupportedType())

    readonly optionalFilters = []

    readonly ignoreInstanceByFilter = null

    protected override mapMapping (params: MappingProxyParams, status: StatusType, topics: ReadonlyRecord<string, string[]>): CybusDetailedMapping {
        return { ...mapDetailedMapping(params, topics), status }
    }

    protected async fetchInformation (proxy: MappingProxy): Promise<ReadonlyRecord<string, string[]>> {
        try {
            return await proxy.getMappedEndpointTopics()
        } catch (e: unknown) {
            throw new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, 'Could not retrieve configured endpoints topics on mapping', {
                id: proxy._targetId,
                message: (e as Error).message,
            })
        }
    }

    protected override getStatusSupportedType (): StatusSupportedType {
        return 'mapping'
    }

    override async onChange (args: MappingHandlerArgs['OnChangeArgs']): Promise<MappingHandlerArgs['OnChangeUnsub']> {
        const [unsubscribe, unsubscribeProxyEvent] = await Promise.all([this.createStatusChangeListener(args), this.createExecutionErrorListener(args)])

        return async (isGone: boolean): Promise<void> => {
            await Promise.all([unsubscribeProxyEvent(isGone), unsubscribe(isGone)])
        }
    }
}
