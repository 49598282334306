import React, { type FC, memo } from 'react'
import { Box, InputAdornment, inputClasses, type SxProps, Tab, Tabs, TextField, type Theme } from '@mui/material'
import { AddRounded, DeleteForeverOutlined, Search } from '@mui/icons-material'

import { CybusPermissionContext, type EditableCybusPermission, Translation } from '../../../../../domain'

import { useTranslator } from '../../../Internationalization'
import { Popover, useAnchor, useAnchorSetter } from '../../../common'

import { useContextName, useIsContextEditable } from '../Hooks'
import { CreationForm } from '../Creation'
import {
    useAdditionDispatcher,
    useClearingDispatcher,
    useContext,
    useContextDispatcher,
    useDisabled,
    useNewPermissionValidation,
    useSearch,
    useSearchDispatcher,
} from './State'
import { ActionButton } from './Button'

const inputStyle: SxProps<Theme> = { transition: ({ transitions: { create } }) => create('opacity'), '&:focus': { opacity: 1 } }
const noValueInputStyle: SxProps<Theme> = { opacity: 0 }
const underlineStyle: SxProps<Theme> = { '&::before': { transition: ({ transitions: { create } }) => create('opacity'), '&:focus': { opacity: 1 } } }
const noValueUnderlineStyle: SxProps<Theme> = { '&::before': { opacity: 0 } }
const searchStyle: SxProps<Theme> = { cursor: 'pointer' }
const searchInputStyles: SxProps<Theme> = { ...inputStyle, ...underlineStyle }

const SearchInput: FC = () => {
    const translator = useTranslator()

    const disabled = useDisabled()
    const search = useSearch()
    const onChange = useSearchDispatcher()

    return (
        <TextField
            data-testid="search-input"
            variant="standard"
            size="small"
            placeholder={translator.formatTranslation(Translation.SEARCH)}
            sx={
                search
                    ? searchInputStyles
                    : { ...searchInputStyles, [`& .${inputClasses.underline}`]: noValueUnderlineStyle, [`& input.${inputClasses.input}`]: noValueInputStyle }
            }
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <Search sx={searchStyle} />
                    </InputAdornment>
                ),
            }}
            disabled={disabled}
            value={search}
            onChange={({ target }) => onChange(target.value)}
            onClick={(e) => (e.currentTarget.querySelector('input') as HTMLInputElement).focus()}
        />
    )
}

const wrapperStyle: SxProps<Theme> = { display: 'flex' }
const tabRootStyle: SxProps<Theme> = { minHeight: 32, height: 32, marginRight: 'auto' }
const tabIndicatorStyle: SxProps<Theme> = { backgroundColor: 'secondary.main' }
const addFormWrapperStyle: SxProps<Theme> = { p: 1, minWidth: 300 }

export const PermissionsTableTabs: FC = memo(() => {
    const disabled = useDisabled()
    const context = useContext()

    const dispatchContext = useContextDispatcher()
    const add = useAdditionDispatcher()
    const clear = useClearingDispatcher()
    const validate = useNewPermissionValidation()

    const translateName = useContextName()
    const isEditable = useIsContextEditable()

    const anchor = useAnchor()
    const setOrigin = useAnchorSetter()
    const close: VoidFunction = () => setOrigin(null)

    return (
        <Box data-testid="permissions-tabs" sx={wrapperStyle}>
            <Tabs
                TabIndicatorProps={{ sx: tabIndicatorStyle }}
                sx={tabRootStyle}
                value={context}
                onChange={(_, newContext: EditableCybusPermission['context']) => dispatchContext(newContext)}
            >
                {Object.values(CybusPermissionContext).map((context) =>
                    isEditable(context) ? (
                        <Tab
                            data-testid={`permissions-tab-${context.toLowerCase()}`}
                            key={context}
                            sx={tabRootStyle}
                            value={context}
                            label={translateName(context)}
                        />
                    ) : null
                )}
            </Tabs>
            <SearchInput />
            <ActionButton data-testid="clear-permissions-button" disabled={!clear} tooltip={Translation.REMOVE_ALL_PERMISSIONS} onClick={clear || undefined}>
                <DeleteForeverOutlined />
            </ActionButton>
            <ActionButton data-testid="add-permission-button" tooltip={Translation.ADD_PERMISSION} onClick={(e) => setOrigin(e.currentTarget)}>
                <AddRounded />
            </ActionButton>
            <Popover onClose={close} anchorOrigin={{ vertical: 'center', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
                {anchor && (
                    <Box sx={addFormWrapperStyle}>
                        <CreationForm
                            disabled={disabled}
                            context={context}
                            onAdd={(permission) => {
                                try {
                                    validate(permission)
                                    add(permission)
                                } catch (e) {
                                    return Promise.reject(e)
                                }

                                close()
                                dispatchContext(permission.context)

                                return Promise.resolve()
                            }}
                        />
                    </Box>
                )}
            </Popover>
        </Box>
    )
})
