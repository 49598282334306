import type { CybusDetailedService } from '../../../../../../domain'

import { mapDetailedService, SERVICE_AGENT } from '../../../../../Connectware'
import { SERVICE_CLASSNAME } from '../../../../constants'
import type { ServiceProxy } from '../../../../proxies'
import {
    createDeviationsEventsHandler,
    createProxyEventsHandler,
    SubscriptionHandlerType,
    type VrpcHandlerMappingPropertiesArgs,
    type VrpcInstanceToOneSubscriptionHandler,
} from '..'

type DetailedServiceHandler = VrpcInstanceToOneSubscriptionHandler<ServiceProxy, CybusDetailedService>
type DetailedServiceHandlerArgs = VrpcHandlerMappingPropertiesArgs<DetailedServiceHandler>

export class VrpcDetailedServiceProxyInstanceHandler implements DetailedServiceHandler {
    private readonly createDeviationsListener = createDeviationsEventsHandler('service')

    private readonly createStateListener = createProxyEventsHandler<ServiceProxy>('state')

    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_ONE

    readonly optionalFilters = []

    readonly requiredFilters = []

    readonly classNameFilter = SERVICE_CLASSNAME

    readonly agent = SERVICE_AGENT

    readonly ignoreInstances = null

    readonly ignoreInstanceByFilter = null

    readonly sourceInstanceName = null

    async onChange (args: DetailedServiceHandlerArgs['OnChangeArgs']): Promise<DetailedServiceHandlerArgs['OnChangeUnsub']> {
        const [unsubscribe, unsubscribeProxyEvent] = await Promise.all([this.createDeviationsListener(args), this.createStateListener(args)])

        return async (isGone: boolean): Promise<void> => {
            await Promise.all([unsubscribeProxyEvent(isGone), unsubscribe(isGone)])
        }
    }

    mapToDomain ({ instance, rstAdapter }: DetailedServiceHandlerArgs['DomainMapperArgs']): Promise<CybusDetailedService> {
        return Promise.all([instance.getParams(), instance.getDependencies(), rstAdapter.fetchResourceDeviations('service', instance._targetId)]).then(
            ([params, dependencies, resourceDeviations]) => mapDetailedService(params, dependencies, resourceDeviations)
        )
    }
}
