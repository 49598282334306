import type { CybusServer } from '../../../../../../domain'

import { mapCybusServer, type StatusSupportedType } from '../../../../../Connectware'
import { OPCUA_SERVER_CLASSNAME_FILTER } from '../../../../constants'
import type { ServerProxy } from '../../../../proxies'
import {
    createStatusEventsHandler,
    excludeInstanceByServiceName,
    SubscriptionHandlerType,
    type VrpcHandlerMappingPropertiesArgs,
    type VrpcInstanceToOneSubscriptionHandler,
} from '..'

type ServerHandler = VrpcInstanceToOneSubscriptionHandler<ServerProxy, CybusServer>
type ServerHandlerArgs = VrpcHandlerMappingPropertiesArgs<ServerHandler>

abstract class VrpcBaseServerProxyToCybusServerHandler implements ServerHandler {
    abstract readonly optionalFilters: ServerHandler['optionalFilters']

    abstract readonly ignoreInstanceByFilter: ServerHandler['ignoreInstanceByFilter']

    readonly type = SubscriptionHandlerType.INSTANCE_ONE_TO_ONE

    readonly requiredFilters = []

    readonly classNameFilter = OPCUA_SERVER_CLASSNAME_FILTER

    readonly ignoreInstances = null

    readonly sourceInstanceName = null

    readonly agent = null

    readonly onChange = createStatusEventsHandler(this.getStatusType())

    protected abstract getStatusType (): StatusSupportedType

    mapToDomain ({ instance, rstAdapter }: ServerHandlerArgs['DomainMapperArgs']): Promise<ServerHandlerArgs['Domain']> {
        return Promise.all([instance.getParams(), rstAdapter.fetchStatusType(this.getStatusType(), instance._targetId)]).then(([params, status]) => ({
            ...mapCybusServer(params),
            status,
        }))
    }
}

export class VrpcServerProxyToCybusServersHandler extends VrpcBaseServerProxyToCybusServerHandler {
    readonly optionalFilters = ['service' as const]

    readonly ignoreInstanceByFilter = excludeInstanceByServiceName

    protected override getStatusType (): StatusSupportedType {
        return 'servers'
    }
}

export class VrpcServerProxyToCybusServerHandler extends VrpcBaseServerProxyToCybusServerHandler {
    readonly optionalFilters = []

    readonly ignoreInstanceByFilter = null

    protected override getStatusType (): StatusSupportedType {
        return 'server'
    }
}
