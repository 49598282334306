import { createInterval, EventListener, isArray, objectEntries, type ReadonlyRecord } from '../../utils'
import { ConnectwareError, ConnectwareErrorType } from '../../domain'

import type { BackendEventStreamResponseContent, BackendPath, BackendQueryParameters } from '../Connectware'

export type RSTWebSocketWrapperOptions<Path extends BackendPath> = Readonly<{
    /**
     * Socket url origin
     */
    origin: URL['origin']
    /**
     * Socket url path
     */
    path: Path
    /**
     * Socket url params without the secret token
     */
    params: BackendQueryParameters<Path> & ReadonlyRecord<string, string | string[]>
    /**
     * Keep alive in milliseconds so the connection doesn't die
     */
    pingInterval: number
}>

/**
 * The minimal naked websocket internally used and wrapped for usage elsewhere in the application
 */
export type InternalWebSocket = Pick<WebSocket, 'addEventListener' | 'close' | 'send' | 'url'>

/**
 * Wrapped websocket to be used from the rest of the application
 */
export type RSTWrappedWebSocket<Path extends BackendPath> = Readonly<{
    on(listener: (event: BackendEventStreamResponseContent<Path> | ConnectwareError) => void): Promise<VoidFunction>
}>

export class RSTWebSocketWrapper<Path extends BackendPath> implements RSTWrappedWebSocket<Path> {
    private readonly listener = new EventListener<BackendEventStreamResponseContent<Path> | ConnectwareError>()

    private socket: InternalWebSocket | null = null

    constructor (private readonly options: RSTWebSocketWrapperOptions<Path>) {}

    private getUrl (): string {
        const { origin, path, params } = this.options

        const normalizedParams = objectEntries(params).flatMap(([key, value]) =>
            (isArray(value) ? (value as string[]) : [value]).map((v) => [String(key), String(v)])
        )

        return `${origin}${path}?${new URLSearchParams(normalizedParams).toString()}`
    }

    private initializeSocket (): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.socket !== null) {
                /**
                 * Socket is already there, so don't bother setting everything up again
                 */
                resolve()
                return
            }

            const socket: InternalWebSocket = new WebSocket(this.getUrl())
            this.socket = socket

            /**
             * Deals with the messages that come back from RST
             */
            socket.addEventListener('message', (e: MessageEvent<BackendEventStreamResponseContent<Path>>) => {
                let data: BackendEventStreamResponseContent<Path> | ConnectwareError

                try {
                    data = JSON.parse(String(e.data))
                } catch (err: unknown) {
                    data = new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Given data is not parsable', {
                        data: e.data,
                        message: (err as ConnectwareError).message,
                    })
                }

                this.listener.trigger(data)
            })

            /**
             * Signal that things have worked out
             */
            socket.addEventListener('open', () => {
                resolve()

                /**
                 * Envoy likes to kick inactive sockets out
                 * @see https://www.youtube.com/watch?v=b8VoxkPc9-w
                 */
                const stopPinging = createInterval(() => socket.send('Keep Yourself Alive'), this.options.pingInterval)
                socket.addEventListener('close', () => stopPinging())
            })

            /**
             * Handle errors (which are very much unexpected)
             */
            socket.addEventListener('error', () =>
                reject(new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, 'There was an unexpected issue with connection', { url: socket.url }))
            )
        })
    }

    private destroySocket (): void {
        const { socket } = this

        if (this.listener.size !== 0 || socket === null) {
            return
        }

        this.socket = null

        socket.close()
    }

    on (listener: (e: BackendEventStreamResponseContent<Path> | ConnectwareError) => void): Promise<VoidFunction> {
        const initialization = this.initializeSocket()
        const off = this.listener.on(listener)

        return initialization.then(() => {
            return () => {
                off()
                this.destroySocket()
            }
        })
    }
}
