import type { GetInstanceOptions, InstanceInfo, VrpcProxy } from 'vrpc'

import { type ChangeEventListener, isArray } from '../../../../../utils'
import { ConnectwareError, ConnectwareErrorType, type PaginatedData, type PaginationParameters } from '../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../application'

import type { RSTAdapter } from '../../../../Connectware'
import { getInstance, type ManagedVrpcRemote } from '../../../utils'
import {
    type VrpcHandlerMappingPropertiesArgs as HandlerMappingArgs,
    type VrpcInstanceToListSubscriptionHandler as ListHandler,
    type VrpcInstanceToOneSubscriptionHandler as OneHandler,
    type VrpcInstanceToPageSubscriptionHandler as PageHandler,
    SubscriptionHandlerType,
    type VrpcInstanceToVirtualOneSubscriptionHandler as VirtualOneHandler,
} from '../handlers'
import { VrpcInstanceBaseMapper, type VrpcInstanceBaseMapperSupportedHandlers } from './BaseVrpcInstance'
import {
    ListInstanceHandlerManager,
    OneInstanceHandlerManager,
    PageInstanceHandlerManager,
    type ResourceManager,
    ResourceManagerCollection,
    VirtualOneInstanceHandlerManager,
} from './Manager'

type ManagerCreationArgs<VrpcInstance> = Readonly<{ remote: ManagedVrpcRemote, instance: Promise<VrpcInstance>, instanceName: string }>

abstract class ManagedInstancesVrpcInstanceBaseMapper<
    Handler extends VrpcInstanceBaseMapperSupportedHandlers<Handler>,
    Manager extends ResourceManager<HandlerMappingArgs<Handler>['Domain']>,
    VrpcInstance extends VrpcProxy,
    Domain
> extends VrpcInstanceBaseMapper<Handler, Domain> {
    protected readonly managersHelper = new ResourceManagerCollection<string, Manager, HandlerMappingArgs<Handler>['Domain']>()

    protected static mapInstanceAddressHash (instanceName: string, options?: InstanceInfo | GetInstanceOptions): string {
        return JSON.stringify([options?.agent, options?.className, instanceName])
    }

    constructor (handler: Handler, filter: SubscriptionFilterArgs, rstAdapter: RSTAdapter) {
        super(handler, filter, rstAdapter)

        this.onDrop(() => this.managersHelper.drop())
        this.onDrop(this.managersHelper.onChange(() => this.triggerChange()))
    }

    protected addInstance (remote: ManagedVrpcRemote, instanceName: string, options?: InstanceInfo | GetInstanceOptions): void {
        const key = ManagedInstancesVrpcInstanceBaseMapper.mapInstanceAddressHash(instanceName, options)
        const manager = this.createManager({ remote, instance: getInstance<VrpcInstance>(remote, instanceName, options), instanceName })

        this.managersHelper
            .addManager(key, manager)
            /** In case there is an error with the setup, then trigger an error event */
            .catch((e: ConnectwareError) => this.triggerError(e))
    }

    protected removeInstance (_: ManagedVrpcRemote, instanceName: string, options: GetInstanceOptions): void {
        this.managersHelper
            .removeManager(ManagedInstancesVrpcInstanceBaseMapper.mapInstanceAddressHash(instanceName, options), true)
            /** In case there is an error with the removal, then trigger an error event */
            .catch((e: ConnectwareError) => this.triggerError(e))
    }

    protected abstract createManager (args: ManagerCreationArgs<VrpcInstance>): Manager
}

export class InstanceToListDataMapper<VrpcInstance extends VrpcProxy, Domain> extends ManagedInstancesVrpcInstanceBaseMapper<
    ListHandler<VrpcInstance, Domain> | OneHandler<VrpcInstance, Domain>,
    ListInstanceHandlerManager<VrpcInstance, Domain> | OneInstanceHandlerManager<VrpcInstance, Domain>,
    VrpcInstance,
    (Domain | ConnectwareError)[]
> {
    protected createManager ({
        instance,
    }: ManagerCreationArgs<VrpcInstance>): ListInstanceHandlerManager<VrpcInstance, Domain> | OneInstanceHandlerManager<VrpcInstance, Domain> {
        const args = { instance, filter: this.filter, rstAdapter: this.rstAdapter }
        return this.handler.type === SubscriptionHandlerType.INSTANCE_ONE_TO_LIST
            ? new ListInstanceHandlerManager(this.handler, args)
            : new OneInstanceHandlerManager(this.handler, args)
    }

    protected getValue (): (Domain | ConnectwareError)[] {
        return this.managersHelper.getValues<(Domain | ConnectwareError)[]>((r, value) => [...r, ...(isArray(value) ? value : [value])], [])
    }
}

const mapOnlySingleValue = <D>(values: (ConnectwareError | D)[], fallback: D | ConnectwareError): D | ConnectwareError => {
    if (values.length > 1) {
        return new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'More than one value found', { count: values.length })
    }

    const [value] = values
    return value ?? fallback
}

abstract class BaseInstanceToOneDataMapper<
    Handler extends OneHandler<VrpcInstance, Domain> | VirtualOneHandler<VrpcInstance, Domain>,
    Manager extends OneInstanceHandlerManager<VrpcInstance, Domain> | VirtualOneInstanceHandlerManager<VrpcInstance, Domain>,
    VrpcInstance extends VrpcProxy,
    Domain
> extends ManagedInstancesVrpcInstanceBaseMapper<Handler, Manager, VrpcInstance, Domain | ConnectwareError> {
    constructor (protected readonly id: string, handler: Handler, filter: SubscriptionFilterArgs, rstAdapter: RSTAdapter) {
        super(handler, filter, rstAdapter)
    }

    protected getValue (): Domain | ConnectwareError {
        return mapOnlySingleValue(
            this.managersHelper.getValues<(Domain | ConnectwareError)[]>((r, value) => [...r, value], []),
            new ConnectwareError(ConnectwareErrorType.NOT_FOUND, 'The VRPC instance was not found', { id: this.id })
        )
    }
}

export class InstanceToOneDataMapper<VrpcInstance extends VrpcProxy, Domain> extends BaseInstanceToOneDataMapper<
    OneHandler<VrpcInstance, Domain>,
    OneInstanceHandlerManager<VrpcInstance, Domain>,
    VrpcInstance,
    Domain
> {
    constructor (
        ...args: ConstructorParameters<
            typeof BaseInstanceToOneDataMapper<OneHandler<VrpcInstance, Domain>, OneInstanceHandlerManager<VrpcInstance, Domain>, VrpcInstance, Domain>
        >
    ) {
        super(...args)

        /** Not the wanted instance, so just drop it */
        this.shouldIgnoreClauses.push((instanceName) => this.id !== instanceName)
    }

    protected createManager ({ instance }: ManagerCreationArgs<VrpcInstance>): OneInstanceHandlerManager<VrpcInstance, Domain> {
        return new OneInstanceHandlerManager(this.handler, { instance, filter: this.filter, rstAdapter: this.rstAdapter })
    }
}

export class InstanceToVirtualOneDataMapper<VrpcInstance extends VrpcProxy, Domain> extends BaseInstanceToOneDataMapper<
    VirtualOneHandler<VrpcInstance, Domain>,
    VirtualOneInstanceHandlerManager<VrpcInstance, Domain>,
    VrpcInstance,
    Domain
> {
    protected createManager ({ instance }: ManagerCreationArgs<VrpcInstance>): VirtualOneInstanceHandlerManager<VrpcInstance, Domain> {
        return new VirtualOneInstanceHandlerManager(this.handler, { id: this.id, instance, filter: this.filter, rstAdapter: this.rstAdapter })
    }
}

export class InstanceToPageDataMapper<VrpcInstance extends VrpcProxy, Domain> extends ManagedInstancesVrpcInstanceBaseMapper<
    PageHandler<VrpcInstance, Domain>,
    PageInstanceHandlerManager<VrpcInstance, Domain>,
    VrpcInstance,
    PaginatedData<Domain> | ConnectwareError
> {
    constructor (
        private readonly optionsChangeListener: ChangeEventListener<PaginationParameters<Domain>>,
        handler: PageHandler<VrpcInstance, Domain>,
        filter: SubscriptionFilterArgs,
        rstAdapter: RSTAdapter
    ) {
        super(handler, filter, rstAdapter)
    }

    protected createManager ({ remote, instance, instanceName }: ManagerCreationArgs<VrpcInstance>): PageInstanceHandlerManager<VrpcInstance, Domain> {
        return new PageInstanceHandlerManager(this.handler, {
            instanceName,
            /** Load proxy or get ConnectwareError */
            instance,
            filter: this.filter,
            remote,
            pagination: this.optionsChangeListener,
            rstAdapter: this.rstAdapter,
        })
    }

    protected getValue (): PaginatedData<Domain> | ConnectwareError {
        return mapOnlySingleValue(
            this.managersHelper.getValues<(PaginatedData<Domain> | ConnectwareError)[]>((r, value) => [...r, value], []),
            new ConnectwareError(ConnectwareErrorType.NOT_FOUND, 'Could not generate page from VRPC instance', { filter: this.filter })
        )
    }
}
