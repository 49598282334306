import React, { type FC } from 'react'
import { PauseCircleFilled, PlayCircleFilled } from '@mui/icons-material'

import {
    canContainerBeDeleted,
    canContainerBeRestarted,
    canContainerBeStarted,
    canContainerBeStopped,
    Capability,
    createIsAuthenticatedWithCapabilitiesSelector,
    type CybusService,
    type CybusServiceContainer,
    ResourceType,
    selectDeletableLoadedServiceContainers,
    selectRestartableLoadedServiceContainers,
    selectServiceContainersPage,
    selectStartableLoadedServiceContainers,
    selectStoppableLoadedServiceContainers,
    Translation,
} from '../../../domain'

import { RelativeRoutePathWithId } from '../routing'
import { RedirectingResourcesTable } from '../Resources'
import { useClearTableSelection } from '../common'
import { DeleteModal, RestartModal, StartModal, StopModal } from './Bulk'
import { useContainerTableColums } from './Columns'

const Modals: FC = () => {
    const clear = useClearTableSelection()

    return (
        <>
            <StopModal
                dimension={ResourceType.SERVICE_CONTAINER}
                usecase="bulkStopServiceContainersUsecase"
                containersSelector={selectStoppableLoadedServiceContainers}
                onConfirmed={clear}
            />
            <StartModal
                dimension={ResourceType.SERVICE_CONTAINER}
                usecase="bulkStartServiceContainersUsecase"
                containersSelector={selectStartableLoadedServiceContainers}
                onConfirmed={clear}
            />
            <RestartModal
                dimension={ResourceType.SERVICE_CONTAINER}
                usecase="bulkRestartServiceContainersUsecase"
                containersSelector={selectRestartableLoadedServiceContainers}
                onConfirmed={clear}
            />
            <DeleteModal
                dimension={ResourceType.SERVICE_CONTAINER}
                usecase="bulkDeleteServiceContainersUsecase"
                containersSelector={selectDeletableLoadedServiceContainers}
                onConfirmed={clear}
            />
        </>
    )
}

const selectCanManageServiceContainers = createIsAuthenticatedWithCapabilitiesSelector(Capability.SERVICE_CONTAINERS_MANAGE)

export const Table: FC<Readonly<{ serviceId?: CybusService['id'] }>> = ({ serviceId = null }) => {
    const { name, lastUpdate, created, status } = useContainerTableColums()

    return (
        <RedirectingResourcesTable<
            'manageServiceContainersUsecase',
            CybusServiceContainer,
            CybusServiceContainer,
            | 'bulkDeleteServiceContainersUsecase'
            | 'bulkRestartServiceContainersUsecase'
            | 'bulkStartServiceContainersUsecase'
            | 'bulkStopServiceContainersUsecase'
        >
            subscriptionUsecase="manageServiceContainersUsecase"
            filter={serviceId ? { service: serviceId } : undefined}
            data={selectServiceContainersPage}
            hasManagementCapabilitiesSelector={selectCanManageServiceContainers}
            dataTableMapper={(containers) => containers}
            columns={{
                ...(serviceId ? {} : { service: { label: Translation.SERVICE_ID, sort: true } }),
                name,
                lastUpdate,
                created,
                volumesCount: { label: Translation.NUMBER_OF_VOLUMES, sort: true },
                status,
            }}
            short={Boolean(serviceId)}
            translations={{ noResultsOrEmptyTable: Translation.NO_CONTAINERS_AVAILABLE, paginationDataType: 'CONTAINER' }}
            redirectOnRowclick={RelativeRoutePathWithId.CONTAINER}
            selection={{
                DELETE: { usecase: 'bulkDeleteServiceContainersUsecase', filter: canContainerBeDeleted },
                DISABLE: { usecase: 'bulkStopServiceContainersUsecase', filter: canContainerBeStopped, icon: PauseCircleFilled, label: Translation.STOP },
                ENABLE: { usecase: 'bulkStartServiceContainersUsecase', filter: canContainerBeStarted, icon: PlayCircleFilled, label: Translation.START },
                RESTART: { usecase: 'bulkRestartServiceContainersUsecase', filter: canContainerBeRestarted },
                resource: 'id',
                usecaseMethod: 'selectContainers',
            }}
        >
            <Modals />
        </RedirectingResourcesTable>
    )
}
