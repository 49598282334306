export enum StatusType {
    ACTIVE = 'ACTIVE',
    AVAILABLE = 'AVAILABLE',
    CONNECTED = 'CONNECTED',
    CONNECTING = 'CONNECTING',
    CREATED = 'CREATED',
    DEAD = 'DEAD',
    DEVIATED = 'DEVIATED',
    DISABLED = 'DISABLED',
    DISABLING = 'DISABLING',
    DISCONNECTED = 'DISCONNECTED',
    DISCONNECTING = 'DISCONNECTING',
    ENABLED = 'ENABLED',
    ENABLING = 'ENABLING',
    EXITED = 'EXITED',
    INACTIVE = 'INACTIVE',
    INVALID = 'INVALID',
    NO_DEVIATION = 'NO_DEVIATION',
    NOT_ENABLED = 'NOT_ENABLED',
    OFFLINE = 'OFFLINE',
    ONLINE = 'ONLINE',
    PAUSED = 'PAUSED',
    REACHABLE = 'REACHABLE',
    RECONNECTING = 'RECONNECTING',
    REENABLING = 'REENABLING',
    RESTARTING = 'RESTARTING',
    ROLLING_BACK = 'ROLLING_BACK',
    RUNNING = 'RUNNING',
    STARTING = 'STARTING',
    STOPPING = 'STOPPING',
    TERMINATED = 'TERMINATED',
    UNAVAILABLE = 'UNAVAILABLE',
    UNKNOWN = 'UNKNOWN',
    UNREACHABLE = 'UNREACHABLE',
    UPDATING = 'UPDATING',
    VALID = 'VALID',
    WAITING = 'WAITING',
    /**
     * Used only when the state coming from the backend is not expected/covered in the rest of the enum
     */
    // eslint-disable-next-line typescript-sort-keys/string-enum
    UNEXPECTED = 'UNEXPECTED',
}
