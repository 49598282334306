import type { Intersection } from 'utility-types'

import type { CybusConnection, CybusDetailedConnection } from '../../../domain'
import { type CommissioningDataResourceProperties, type ConnectionProxyParams, mapResourceNames, safelyMapPlainProperties } from '..'

type NormalizedConnectionProps = Pick<
    CybusDetailedConnection,
    'host' | 'port' | 'deviceAddress' | 'deviceInstance' | 'ipAddress' | 'cncType' | 'agent' | 'className' | 'device' | 'localInterface' | 'brokers'
>

type ConnectionResource = CommissioningDataResourceProperties<'Cybus::Connection'>
type ConnectionProtocolMapperProperties<P extends ConnectionResource['protocol']> = Extract<ConnectionResource, Readonly<{ protocol: P }>>['connection']

const defaultMapper = (connection: ConnectionProtocolMapperProperties<string>): Partial<NormalizedConnectionProps> => ({
    host: 'host' in connection && typeof connection.host === 'string' ? connection.host : null,
    port: 'port' in connection && (typeof connection.port === 'string' || typeof connection.port === 'number') ? String(connection.port) : null,
})

const protocolPropertiesCustomMappers = {
    Bacnet: ({ deviceAddress, deviceInstance }: ConnectionProtocolMapperProperties<'Bacnet'>): Partial<NormalizedConnectionProps> => {
        const { host = null, port = null } = /^(?<host>.+):(?<port>[0-9]{2,5})$/.exec(deviceAddress)?.groups ?? {}
        return { deviceAddress, deviceInstance: String(deviceInstance), host, port }
    },
    Heidenhain: ({ ipAddress, cncType }: ConnectionProtocolMapperProperties<'Heidenhain'>): Partial<NormalizedConnectionProps> => ({
        ipAddress: ipAddress || null,
        cncType: cncType || null,
    }),
    GenericVrpc: ({ vrpc }: ConnectionProtocolMapperProperties<'GenericVrpc'>): Partial<NormalizedConnectionProps> => ({
        agent: vrpc.agent || null,
        className: vrpc.className || null,
    }),
    Profinet: ({ localInterface }: ConnectionProtocolMapperProperties<'Profinet'>): Partial<NormalizedConnectionProps> => ({
        localInterface: localInterface || null,
    }),
    Kafka: ({ brokers }: ConnectionProtocolMapperProperties<'Kafka'>): Partial<NormalizedConnectionProps> => ({ brokers }),
    Ads: ({ host, routerTcpPort }: ConnectionProtocolMapperProperties<'Ads'>): Partial<NormalizedConnectionProps> => ({
        host: host || null,
        port: typeof routerTcpPort === 'number' ? String(routerTcpPort) : null,
    }),
}

export const mapConnectionProperties = ({ protocol, agentName, connection }: ConnectionResource): NormalizedConnectionProps => {
    const mapper =
        protocol in protocolPropertiesCustomMappers ? protocolPropertiesCustomMappers[protocol as keyof typeof protocolPropertiesCustomMappers] : defaultMapper

    return {
        host: null,
        port: null,
        deviceAddress: null,
        deviceInstance: null,
        ipAddress: null,
        className: null,
        localInterface: null,
        cncType: null,
        device: null,
        brokers: null,
        agent: agentName || null,
        ...mapper(connection as never),
    }
}

type BaseConnection = Intersection<CybusDetailedConnection, CybusConnection>

const mapBaseConnection = ({ id, ...properties }: ConnectionProxyParams): Omit<BaseConnection, 'status'> => {
    const [service, name] = mapResourceNames(id)

    return { id, name, service, ...mapConnectionProperties(properties) }
}

export const mapDetailedConnection = (params: ConnectionProxyParams): Omit<CybusDetailedConnection, 'status'> => ({
    ...mapBaseConnection(params),
    extraConfiguration: safelyMapPlainProperties(params.connection),
})

export const mapConnection = (params: ConnectionProxyParams): Omit<CybusConnection, 'status'> => ({
    ...mapBaseConnection(params),
    protocol: params.protocol,
})

export const INVALID_CONNECTION_INSTANCE_PATTERN = /^__internal__.*$/i
