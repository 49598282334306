import { areCybusServiceDeviationsEquals, Capability, type CybusServiceDeviation } from '../../../../../../domain'

import { type BackendJsonResponseContent, CybusServiceDeviationMapper } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

export class DeviationsStrategy extends BaseSubscriptionStrategy<'serviceDeviations'> {
    readonly requiredPermissions = [Capability.SERVICE_DEVIATIONS_READ]

    protected override readonly areEquals = areCybusServiceDeviationsEquals

    override async retrieveAll (): Promise<CybusServiceDeviation[]> {
        return this.retrieve({
            capability: Capability.SERVICE_DEVIATIONS_READ,
            method: 'GET',
            path: '/api/services/instances',
            mapper: (data: BackendJsonResponseContent<'/api/services/instances', 'get', 200>) => {
                const mapper = new CybusServiceDeviationMapper((id) => this.rstAdapter.fetchIsDeviated('serviceDeviations', id))

                for (const id of data) {
                    mapper.push(id)
                }

                return mapper.values
            },
        })
    }
}
